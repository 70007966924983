<div class="page-title"> {{'component.calendar.calendar' | translate}}</div>
<ul class="breadcrumb">
  <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}}
      </span>{{'general.breadcrumb.home' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li *ngIf="organization != null"><a
      [routerLink]="['/organizations',organization.organization_type_id ? organization.organization_type_id : 0, organization.id]">{{organization.name}}</a>
  </li>
  <li *ngIf="organization != null"><i class="pi pi-angle-right"></i></li>
  <li><a [routerLink]="['/calendar']"> {{'component.calendar.calendar' | translate}}</a></li>
</ul>
<!--
<p-card>
  <ng-template pTemplate="header">
    <div class="p-card-title">
      {{'component.calendar.filter' | translate}}
    </div>
  </ng-template>
  <div class="toolbar-panel">

    componentFilter-->
<!--<div class="actions p-ml-2" *ngIf="has_filter_panel">
  <ng-container *ngFor="let action of componentFilter.headerActions">
    <a *ngIf="isActionVisible(action, item) && !locked" (click)="onActionClick(action, item, $event)" class="p-button {{action.class}}">
      <fa-icon *ngIf="action.icon!=null" [icon]="action.icon"></fa-icon>
      <span>&nbsp;{{getActionLabel(action, item)}}</span>
      <br *ngIf="debugService.enabled" /><small *ngIf="debugService.enabled">{{view.code}}.{{action.name}}</small>
    </a>
  </ng-container>
</div>-->
<!--</div>



  <div class="p-grid">
    <div class="p-col p-field">
      <label for="organizations">
        {{'organization.entity.name' | translate}}
      </label>
      <p-dropdown name="organizations" [(ngModel)]="selectedOrganization" (onChange)="onOrganizationChange()"
                  [options]="organizations" optionLabel="name" placeholder="{{'general.select' | translate}}" [showClear]="true"
                  appendTo="body" [filter]="true" (onFilter)="loadOrganizations($event)" emptyFilterMessage="No existen datos">
        <ng-template pTemplate="selectedItem">
          <div *ngIf="selectedOrganization">
            {{selectedOrganization.name}} <span *ngIf="selectedOrganization.tradename!=null">({{selectedOrganization.tradename}})</span>
          </div>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <div>
            {{item.name}} <span *ngIf="item.tradename!=null">({{item.tradename}})</span>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="p-col p-field">
      <label for="asunto">
        {{'task.entity_fields.subject' | translate}}
      </label>
      <input type="text" name="asunto" [(ngModel)]="subject" (ngModelChange)="loadMeeting()" placeholder="">
    </div>
    <div class="p-col p-field">
      <label for="state">
        {{'task.entity_fields.task_status_id' | translate}}
      </label>
      <p-dropdown name="states" [(ngModel)]="selectedState" (onChange)="onStateChange()" [options]="states"
                  optionLabel="name" placeholder="{{ 'general.select' | translate}}" [showClear]="true" appendTo="body" [filter]="true"
                  (onFilter)="loadStates($event)" emptyFilterMessage="No existen datos"></p-dropdown>
    </div>
  </div>






</p-card>  -->
<div class="toolbar-panel">
  <a class="no-mobile p-button p-button-secondary filters" [class.p-button-badge-exclamation]="hasFilterValue"
    (click)="toggleFilter()" *ngIf="has_filter_panel">
    <i class="fas fa-filter" severity="warning"></i>&nbsp;{{'general.action.filters' | translate}}
  </a>
  <a class="mobile p-button p-button-secondary filters" (click)="toggleFilterMobile()" *ngIf="has_filter_panel">
    <i class="fas fa-filter" severity="warning"></i>
    &nbsp;{{'general.action.filters' | translate}}
  </a>
  <a class="p-button p-button-primary p-ml-2 " (click)="viewMeeting(null, null)">
    <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>
    {{'component.calendar.new_meeting' | translate}}
  </a>
</div>
<div class="p-d-flex p-mt-4" id="calendarEvents" style="position: relative">
  <div class="flex-grow-1">
    <p-card>
      <ng-template pTemplate="body" styleClass="">
        <div class="grid p-mt-4">
          <!--<p-fullCalendar [events]="events" [options]="options" dateFormat="mm/yy" [yearNavigator]="true"
          yearRange="2000:2030" [stepMinute]="5"></p-fullCalendar>-->
          <full-calendar [events]="events" [options]="options" dateFormat="mm/yy" [yearNavigator]="true"
            yearRange="2000:2030" [stepMinute]="5"></full-calendar>
        </div>
      </ng-template>

    </p-card>
  </div>
  <div style="flex: 0 0 250px" class="p-ml-3" *ngIf="loginService.hasPermission('CALENDAR_OTHERS_USERS')">
    <p-card>
      <ng-template pTemplate="header">
        <div class="p-card-title">
          {{'component.calendar.calendars' | translate}}
        </div>
      </ng-template>
      <ng-template pTemplate="body" styleClass="">
        <div class="p-grid p-mt-4">
          <div *ngFor="let user of users" class="p-col-12">
            <p-checkbox binary="true" (onChange)="onChangeUser(user)" [ngModel]="user.selected"
              [ngModelOptions]="{standalone: true}" [styleClass]="getUserCheckClass(user)" [label]="user.name"
              [style]="{ 'background-color': user.color?user.color:'#e8e8e8' }">
            </p-checkbox>
          </div>
        </div>
      </ng-template>
    </p-card>
  </div>


  <div [ngStyle]="{'top':yPos,'left':xPos,'position': 'absolute','z-index': 10000,
  'min-width':'256px',
  'max-width':'512px',
  'word-wrap':'break-word',
  'color':'#5E5873',
  'padding':'5px 10px',
  'border-radius':'6px'
  }" ckass="tooltip_div" *ngIf="tooltip_info.show">
    <span class="tooltiptext"
      style="background-color: {{tooltip_info.backgroundColor}}; color: {{tooltip_info.textColor}}">
      <b>{{tooltip_info.showType}} - ({{tooltip_info.state}})</b><br />
      {{tooltip_info.description}} <br />
      {{tooltip_info.date_from | date:'dd/MM/yyyy HH:mm'}} ({{tooltip_info.duration}}) <br />
      <div *ngIf="tooltip_info.organization != null && tooltip_info.organization != ''">
        {{tooltip_info.organization?tooltip_info.organization:''}}
      </div>
      <div *ngIf="tooltip_info.responsible != null && tooltip_info.responsible != ''">
        {{tooltip_info.responsible}}
      </div>
      <div *ngFor="let participant of tooltip_info.participants; let i = index">
        <div *ngIf="i<3">
          {{participant.user?participant.user.name:participant.external_user}}
          {{participant.user?'('+participant.user.email+')':''}}
        </div>
      </div>
      <div *ngIf="tooltip_info.participants.length > 3">
        +{{tooltip_info.participants.length-3}} participante/s...
      </div>
      <div
        style="position: absolute;bottom:100%;left:6%;margin-left:-5px;border-width:5px;border-style:solid;border-color:transparent transparent {{tooltip_info.backgroundColor}} transparent;">

      </div>
    </span>
  </div>


</div>

<div *ngIf="show_filter_panel || show_filter_panel_mobile">
  <p-sidebar [(visible)]="show_filter_panel" position="right"
    styleClass="p-sidebar-content-no-padding p-sidebar-content-no-footer">
    <ng-template pTemplate="header">{{'general.filter.title' | translate}}</ng-template>
    <!--{{componentFilter | json}}
    <app-view-component-filter [component]="componentFilter" [model]="model" (onEvent)="onComponentEvent($event)" [view]="view"></app-view-component-filter>-->
    <div class="fixed-toolbar border-top border-bottom p-pb-3">
      <div class="p-d-flex p-pt-3 p-pl-3 p-pr-3">

        <a class="p-button p-button-primary p-button-text " label="Limpiar filtros" (click)="resetFilters()"><i
            class="pi pi-filter-slash"></i>&nbsp;{{'general.filter.clean' | translate}}</a>
        <div class="flex-grow-1"></div>
        <a class="p-button" (click)="loadMeetingFilter()"> {{'general.filter.search' | translate}} </a>

      </div>
    </div>
    <div class="p-d-flex p-flex-column " style="scroll-behavior:auto">
      <div class="p-field p-p-3 p-m-0 border-bottom ">
        <label for="organizations">
          {{'organization.entity.name' | translate}}
        </label>
        <p-dropdown name="organizations" [(ngModel)]="selectedOrganizationId" [options]="organizations"
          optionLabel="name" optionValue="id" placeholder="{{'general.select' | translate}}" [showClear]="true"
          appendTo="body" [filter]="true" (onFilter)="loadOrganizations($event)" emptyFilterMessage="No existen datos">
          <ng-template let-selectedItem pTemplate="selectedItem">
            <div *ngIf="selectedOrganizationId">
              {{selectedItem.name}} <span
                *ngIf="selectedOrganizationId.tradename!=null">({{selectedItem.tradename}})</span>
            </div>
          </ng-template>
          <ng-template let-item pTemplate="item">
            <div>
              {{item.name}} <span *ngIf="item.tradename!=null">({{item.tradename}})</span>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="p-field p-p-3 p-m-0 border-bottom  ">
        <label for="asunto">
          {{'task.entity_fields.subject' | translate}}
        </label>
        <input type="text" name="asunto" [(ngModel)]="subject" placeholder="" (keyup.enter)="loadMeetingFilter()">
      </div>
      <div class="p-field p-p-3 p-m-0 border-bottom  ">
        <label for="state">
          {{'task.entity_fields.task_status_id' | translate}}
        </label>
        <p-dropdown name="states" [(ngModel)]="selectedStateId" [options]="states" optionLabel="name" optionValue="id"
          placeholder="{{ 'general.select' | translate}}" [showClear]="true" appendTo="body" [filter]="true"
          (onFilter)="loadStates($event)" emptyFilterMessage="No existen datos"></p-dropdown>
      </div>
    </div>
  </p-sidebar>
</div>