import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
//import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AttachmentsDetailComponent } from 'src/app/attachments/attachments-detail/attachments-detail.component';
import { AttachmentsService } from 'src/app/attachments/attachments.service';
import { CalendarComponent } from 'src/app/calendar/calendar.component';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { SearchService } from 'src/app/core/search.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ViewsService } from 'src/app/core/view/views.service';
import { MailingDetailComponent } from 'src/app/mailing/mailing-detail/mailing-detail.component';
import { UserCalendarComponent } from 'src/app/user-calendar/user-calendar.component';
import { UsersService } from 'src/app/users/users.service';
import { LoginService } from '../../../login/login.service';
import { OrganizationsService } from '../../organizations.service';
import { ContactsService } from '../organizations-contacts-detail/contacts.service';
import { OrganizationsContactsDetailComponent } from '../organizations-contacts-detail/organizations-contacts-detail.component';
import { OrganizationsDetailGeneralComponent } from '../organizations-detail-general/organizations-detail-general.component';
import { MeetingStatesService } from './meeting-states.service';
import { MeetingsUsersService } from './meetings-users.service';
import { MeetingsService } from './meetings.service';
import { Location } from '@angular/common';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { AddCampaignsToEntityComponent } from 'src/app/campaigns/add-campaigns-to-entity/add-campaigns-to-entity.component';

@Component({
  selector: 'app-organizations-meetings-detail',
  templateUrl: './organizations-meetings-detail.component.html',
  styleUrls: ['./organizations-meetings-detail.component.scss']
})
export class OrganizationsMeetingsDetailComponent implements OnInit, OnDestroy {
  @ViewChild("view") public view: ViewComponent;
  @ViewChild(UserCalendarComponent) userCalendarComponent: UserCalendarComponent;

  public searchResults: any[] = [];
  //Meeting:
  public meeting: any = {
    id: 0,
    organization_id: null,// this.config.data.id,
    task_id: null,//this.config.data.taskId,
    meetingUsers: []
  };
  public selectedUsers: any[] = [];
  public meetingStates: any[];
  public users: any[] = [];
  public externalUser: any = { id: null, name: this.translateService.instant("component.organizations.meetings_detail.external_participant") };
  public user: any = this.externalUser;
  public username: any;
  /* public durationOptions = [
     { name: "30M", value: 30 },
     { name: "1H", value: 60 },
     { name: "1H 30M", value: 90 },
     { name: "2H", value: 120 },
     { name: "2H 30M", value: 150 },
     { name: "3H", value: 180 },
   ];*/

  public canOpenCalendar: boolean = false;

  //interval
  private dateInterval: any = null;
  private lastDate: Date = null;

  //Organization:
  public organization: any = {};
  public organizations: any[];

  //Contacts:
  public contact_id: any;
  public contact: any = {};
  public contacts: any[];
  public contacts_options: any[];

  //Responsibles:
  public responsibles: any[];

  public deactivated = false;// this.config.data.deactivated;
  public elementos: any[] = [];
  public meetingUsers: any[] = [];
  public disabledMeeting: boolean = false;
  public deleteMeeting: boolean = true;
  public writeMeeting: boolean = true;

  constructor(
    private organizationsService: OrganizationsService,
    private dialogService: DialogService,
    private usersService: UsersService,
    private meetingsService: MeetingsService,
    private meetingStatesService: MeetingStatesService,
    private contactsService: ContactsService,
    private responsiblesService: UsersService,
    private meetingsUsersService: MeetingsUsersService,
    private attachmentsService: AttachmentsService,
    private searchService: SearchService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    //private config: DynamicDialogConfig,
    //private dynamicDialogRef: DynamicDialogRef,
    //private dialogService: DialogService,
    private _location: Location,
    private coreDialogService: CoreDialogService,
    private viewsService: ViewsService,
    private loginService: LoginService,
    public routerLink: Router,
    private translateService: TranslateService
  ) {
    if (this.route.snapshot.paramMap.has('id')) var numb = parseInt(this.route.snapshot.paramMap.get('id'));
  }

  ngOnInit(): void {
    this.deleteMeeting = this.loginService.hasPermission("MEETINGS_DELETE");
    this.writeMeeting = this.loginService.hasPermission("MEETINGS_WRITE");
    this.canOpenCalendar = this.loginService.hasPermission("CALENDAR");

    const params = this.route.snapshot.queryParams;
    const id = params.id != null ? parseInt(params.id) : null;
    const contactId = params.contactId != null ? parseInt(params.contactId) : null;
    this.contact_id = contactId;
    const meetingId = params.meetingId != null ? parseInt(params.meetingId) : null;
    const organizationId = params.id != null ? parseInt(params.id) : null;
    if (organizationId) this.meeting.organization_id = organizationId;
    if (contactId) this.meeting.contact_id = contactId;
    this.deactivated = params.deactivated;
    const from = params.from;

    this.loadData(meetingId);
    this.loadDataOrganization(id);
    this.loadDataMeetingStates();
    this.loadDataResponsibles();

    this.dateInterval = setInterval(() => {
      if (this.userCalendarComponent != null && this.lastDate != this.meeting.from) {
        this.lastDate = this.meeting.from;
        this.userCalendarComponent.setDate(this.meeting.from);
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    if (this.dateInterval != null) clearInterval(this.dateInterval);
  }

  loadData(id: number) {
    if (id != 0) {
      this.meetingsService.get(id).subscribe({
        next: (data: any) => {
          this.meeting = data;
          this.selectedUsers = this.meeting.meetingUsers.map(m => m.user_id);
          //Comprobar si puedo editarlo, mientras no sea superAdmin. Si es superadmin, omitimos la comprobación:
          if (!(localStorage.getItem("isSuperAdmin") === "true") && !this.loginService.canEditModel(this.meeting, this.view?.entity?.code)) {
            this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: this.translateService.instant("component.organizations.meetings_detail.not_permission") });
            this.disabledMeeting = true;
          }
          //this.loadMeetingUsers();
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    } else {
      this.meeting.responsible_user_id = parseInt(localStorage.getItem('userId'));
      var now = new Date();
      var nowMultiple = now.setMinutes(Math.ceil(now.getMinutes() / 15) * 15);
      //si es porque le han dado a un punto del calendario, que rellena el from sin el resto de datos.
      //this.meeting.from = (this.config.data != null && this.config.data.from != null ? this.config.data.from : new Date(nowMultiple));
      this.meeting.from = (this.route.snapshot.queryParams.from != null && this.route.snapshot.queryParams.from != null ? new Date(this.route.snapshot.queryParams.from) : new Date(nowMultiple));
      this.meeting.duration = 30;
      this.meeting.meeting_state_id = 1;
    }
  }

  loadDataOrganization(id: number) {
    //Carga el organization de esta reunión:
    if (id) {
      this.organizationsService.get(id).subscribe({
        next: (data: any) => {
          this.organization = data;
          this.loadDataContacts(this.contact_id);
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    } else {
      this.loadDataContacts(0);
    }
  }

  loadOrganizations() {
    //Carga todas las opciones de organizations:
    this.organizationsService.getCombo({}).subscribe({
      next: (data: any) => {
        this.organizations = data.rows;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  loadDataContacts(id: number) {
    //Carga el contact:
    if (id) {
      this.contactsService.get(id).subscribe({
        next: (data: any) => {
          this.contact = data;
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    }
    //Carga todas las opciones de contacts (si existe organización, sino nada.):    
    if (typeof this.organization.id != "undefined") {
      this.contactsService.all({ organization_id: this.organization.id }).subscribe({
        next: (data: any) => {
          this.contacts = data.rows;
          this.contacts_options = this.getSelectUsers();
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    }
  }

  loadDataMeetingStates() {
    this.meetingStatesService.all({}).subscribe({
      next: (data: any) => {
        this.meetingStates = data.rows;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  loadDataResponsibles() {
    this.meetingsService.users({}).subscribe({
      next: (data: any) => {
        this.responsibles = data.rows;
        this.users = [];
        this.users.push(this.externalUser);
        this.responsibles.forEach(val => this.users.push(Object.assign({}, val)));
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  getSelectUsers() {
    var meetingUserIds = this.meeting.meetingUsers.map((user) => { return user.user_id });
    var selectUsers = this.users.filter((user) => {
      return meetingUserIds.indexOf(user.id) < 0 || user.id == null;
    });
    return selectUsers;
  }

  getUserName(element) {
    this.responsiblesService.get(element.user_id).subscribe(
      data => {
        var user = data;
        Object.assign(element, user);
      }
    )
  }

  addUser() {
    if (this.user.external_user) {
      this.user.external_user = this.user.external_user.trim();
      var validateEmail = this.validateEmail(this.user.external_user);
      if (!validateEmail) {
        this.messageService.add({ closable: false, severity: 'error', detail: this.translateService.instant("component.organizations.meetings_detail.enter_valid_email") });
        return;
      }
      this.meeting.meetingUsers.push({
        meeting_id: this.meeting.id,
        user_id: this.user?.user?.id,
        external_user: this.user?.external_user,
        //user: this.user?.user
      });
      this.user = {};
    } else if (this.user.user && this.user.user.id) {
      this.meeting.meetingUsers.push({
        meeting_id: this.meeting.id,
        user_id: this.user?.user?.id,
        //user: this.user?.user
      });
      this.contacts_options = this.contacts_options.filter(m => m.id != this.user.user.id);
      this.user = {};
    } else {
      this.messageService.add({ closable: false, severity: 'error', detail: this.translateService.instant("component.organizations.meetings_detail.enter_valid_email") });
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  deleteMeetingUser(index) {

    var spliceado = this.meeting.meetingUsers.splice(index, 1);
    if (spliceado[0].user != null) this.contacts_options.push(spliceado[0].user);
  }
  deleteExternalUser(selectedUser) {
    var i = 0;
    this.meetingUsers.forEach(element => {
      if (element.external_user == selectedUser.external_user) {
        this.meetingUsers.splice(i, 1);
      }
      i++;
    });
  }
  updateTo() {
    var fecha = new Date(this.meeting.from);
    var minutes = fecha.getMinutes();
    fecha.setMinutes(minutes + this.meeting.duration);
    this.meeting.to = fecha;
  }

  cancel() {
    this._location.back();
    //this.dynamicDialogRef.close();
  }
  save(event: any, send: boolean) {
    if (typeof this.meeting.id === "undefined" || this.meeting.id == 0) {
      if (this.meeting.transaction_id == null || this.meeting.transaction_id == 0) this.meeting.transaction_id = null;//this.config.data.transaction_id;
      this.meetingsService.add(this.meeting).subscribe({
        next: (data: any) => {
          this.meeting = data;
          this.loadData(this.meeting.id);
          this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.organizations.meetings_detail.add_meeting_correct") });
          if (send) {
            //this.sendInvitations(data);
            //this.newMail(this.meeting.contact.email);
            this.prepareEmailsToSend();
          } else {
            //¿Hay alguna forma de pasar este data en la creación de un meeting al task desde donde el que se está creando sin hacer un close?
            //this.dynamicDialogRef.close(data);
            var params = {
              id: this.meeting ? this.meeting.organization_id : 0,
              contactId: this.meeting ? this.meeting.contact_id : 0,
              meetingId: this.meeting ? this.meeting.id : 0,
              deactivated: this.meeting ? true : false
            }
            // Utiliza router.navigate y pasa el objeto de parámetros
            this.routerLink.navigate(['/meetings/', (this.meeting ? this.meeting.id : 0)], { queryParams: params });
          }
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    } else {
      this.meetingsService.save(this.meeting.id, this.meeting).subscribe({
        next: (data: any) => {
          this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.organizations.meetings_detail.save_meeting_correct") });
          if (send) {
            //this.sendInvitations(data);
            this.prepareEmailsToSend();
          } else {
            //this.dynamicDialogRef.close();
            this._location.back();
          }
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    }
  }

  delete() {
    this.coreDialogService.confirm({
      message: "¿Quieres borrar la reunión " + this.meeting.subject + "?",
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.meetingsService.delete(this.meeting.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            //this.dynamicDialogRef.close();
            this._location.back();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    })
  }

  downloadFile(attachment) {
    this.attachmentsService.download(attachment.guid);
  }

  viewAttachment(attachment) {
    const ref = this.dialogService.open(AttachmentsDetailComponent, {
      data: {
        id: attachment ? attachment.id : 0,
        entity_id: 3,
        entity_pk_id: this.meeting.id,
        organization_id: this.meeting.organization_id
      },
      //header: attachment ? "Detalles del adjunto" : "Nuevo adjunto",
      width: '70%',
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      this.view.fireEvent("meetings.attachments", "reload");
    });
  }

  deleteAttachment(attachment) {
    this.coreDialogService.confirm({
      message: "¿Quieres borrar el adjunto " + attachment.filename + "?",
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.attachmentsService.delete(attachment.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.view.fireEvent("meetings.edit.attachments", "reload");
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    })
  }

  filterSearch(event) {
    let query = event.query;
    if (query.length >= 3) {
      this.searchService.onlyPeople({ search_text: query, skip_contacts_without_email: true }).subscribe(
        data => {
          this.searchResults = data;
        },
        error => {
          this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        }
      );
    }
  }

  addEntitySearch() {
    var index = this.meeting.meetingUsers.findIndex(p => p.user_id == this.user?.external_user?.id);
    if (index >= 0) {
      this.user.external_user = null;
      return;
    }
    if (this.user.external_user.entity_type == "users") {
      this.meeting.meetingUsers.push({
        meeting_id: this.meeting.id,
        user_id: this.user?.external_user?.id,
        external_user: this.user?.external_user?.text,
        //user: this.user?.user,
        name: this.user?.external_user?.text,
        email: this.user?.external_user?.email,
      });
    } else if (this.user.external_user.entity_type == "contacts") {
      this.meeting.meetingUsers.push({
        meeting_id: this.meeting.id,
        contact_id: this.user?.external_user?.id,
        external_user: this.user?.external_user?.email,
        name: this.user?.external_user?.text,
        email: this.user?.external_user?.email,
        //user: this.user?.user
      });
    }
    this.user = {};
  }

  isDuplied(event, send) {
    this.updateDateTo();
    this.meeting.responsible = null;

    if (!this.view.getComponentForm("meetings.edit.general").valid) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }

    if (this.meeting.responsible_user_id == null) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("component.organizations.meetings_detail.organization_mandatory") });
      return;
    }

    if (send && this.meeting.meetingUsers.length == 0 && !this.meeting.contact?.id) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("component.organizations.meetings_detail.not_participants") });
      return;
    }
    /*this.meetingsService.isDuplied(this.meeting).subscribe(
      data => {
        if (data == true) {
          this.coreDialogService.notify({
            message: "Ya existe una reunión con estos datos proporcionados.",
            header: 'Error',
            icon: 'far, exclamation-circle',
            color: 'var(--orange-500)',
            accept: () => { }
          });
        } else {
          this.save(event, send);
        }
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    );*/
    this.save(event, send);
  }

  onChangeContact(contact) {
    if (contact.email != null && contact.email.length > 0) {
      if (this.emailExists(this.meeting.meetingUsers, contact.email)) return;
      this.meeting.meetingUsers.push({
        meeting_id: this.meeting.id,
        user_id: null,
        external_user: contact.email,
        user: null
      });
    }
  }

  emailExists(meetingUsers, email) {
    let found = false;
    for (let i = 0; i < meetingUsers.length; i++) {
      if (meetingUsers[i].external_user === email || meetingUsers[i].user?.email === email) {
        found = true;
        break;
      }
    }
    if (found) {
      //El email buscado se encuentra en el array.
      return true;
    } else {
      //El email buscado no se encuentra en el array.
      return false;
    }
  }

  newMail(meeting_users_emails: any, template_type_code, searchRelated) {
    var emailAcc = null;
    if (template_type_code == "MEETING") {
      if (this.meeting.contact != null) {
        emailAcc = {
          name: this.meeting.contact?.name,
          //email: this.meeting.contact?.email,
          contact_id: this.meeting.contact?.id,
          organization_id: this.meeting.contact?.organization_id,
        }
      }
    } else { emailAcc = meeting_users_emails }
    const ref = this.dialogService.open(MailingDetailComponent, {
      data: {
        to: emailAcc,
        entity_id: 3,
        entity_pk_id: this.meeting.id,
        organization_id: this.meeting.organization_id,
        contact_id: this.meeting.contact_id,
        template_type_code: template_type_code,
        meeting_users: meeting_users_emails,
        searchRelated: searchRelated
      },
      header: this.translateService.instant("general.new_mail_title"),
      width: '70%',
    });
    ref.onClose.subscribe((data: any) => {
      //if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      //if (data) this.dynamicDialogRef.close();
      //location.reload();
      this.view.fireEvent("organizations.preview.mails", "reload");
    });
  }

  prepareEmailsToSend() {
    //Guardamos ids de usuarios que NO sean externals:
    var meeting_users_contacts_ids = this.meeting.meetingUsers.filter(m => m.user_id != null).map(m => m.user_id);
    //Filtramos los emails de los usuarios que SÍ sean externals:
    var meeting_users_emails = this.meeting.meetingUsers.filter(m => !m.user_id).map(m => m.external_user);
    var meeting_users = [];
    meeting_users_emails.forEach(item => {
      meeting_users.push({ name: "", email: item });
    })

    //Si hay algún meetingUser que no sea external...
    if (meeting_users_contacts_ids != null && meeting_users_contacts_ids.length > 0) {
      //Buscamos las filas para recoger sus emails y abrimos popup.
      this.usersService.all({ usersIds: meeting_users_contacts_ids }).subscribe({
        next: (data: any) => {
          var contacts = data.rows;
          //var contacts_emails = contacts.map(m => m.email);
          contacts.forEach(contact_email => {
            meeting_users.push({ name: contact_email.name, email: contact_email.email });
          });
          this.newMail(meeting_users, "MEETING", true);
        }
      });
    } else {
      //Si no, simplemente abrimos el popup.
      this.newMail(meeting_users, "MEETING", true);
    }
  }

  openCalendar() {
    const ref = this.dialogService.open(CalendarComponent, {
      data: {
        meeting_date_from: this.meeting.from,
        organization_id: this.organization.id,
        canOpenMeeting: false,
      },
      header: this.translateService.instant("component.organizations.meetings_detail.calendar"),
      width: "70%"
    });
  }
  refreshAll() {
    this.view.view?.configuration?.components?.array?.forEach(component => {
      this.view.fireEvent(component.code, "reload");
    });
  }

  updateDateTo() {
    if (this.meeting.duration != null && this.meeting.from != null) {
      var fechaTo = new Date(this.meeting.from);
      fechaTo.setMinutes(fechaTo.getMinutes() + this.meeting.duration);
      this.meeting.to = fechaTo;
    }
  }
  unlinkCampaign(campaign) {
    this.meeting.campaign_id = null;
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.meetings.unlink_campaign", { name: campaign.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.meetingsService.save(this.meeting.id, this.meeting).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.refreshAll();
            //this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        })
      },
      reject: () => {

      }
    });
  }
  openCampaign(campaign) {
    window.open("/campaigns/" + (campaign.id ? campaign.id : 0));
  }
  addCampaigns() {
    const ref = this.dialogService.open(AddCampaignsToEntityComponent, {
      data: {
        id: this.meeting.id,
        entity: 'meeting',
        campaign_id: this.meeting.campaign_id
      },
      header: this.translateService.instant("component.campaigns.addCampaigns"),
      width: '70%'
    });
    ref.onClose.subscribe((data: any) => {
      this.refreshAll();
    });
  };

  navigateCalendarFiltered() {
    //Pasar parámetros por ruta sin necesidad de añadirlos a la URL:
    var params = {
      organizationId: this.meeting ? this.meeting.organization_id : 0,
    }
    // Utiliza router.navigate y pasa el objeto de parámetros
    this.routerLink.navigate(['/calendar'], { queryParams: params });
  }

  onEvent(event: any) {
    //if (event.event == "closeDialog" && event.action.name == "closeDialog") this.dynamicDialogRef.close();
    if (event.event == "refreshAll" && event.action.name == "refreshAll") this.refreshAll();
    if (event.component == "meetings.edit.general") {
      if (event.event == "onChange" && event.entityField.full_model_property == "meetings.contact_id") {
        //Si se cambia el contacto...
        if (this.meeting.contact.id != null) {
          this.onChangeContact(this.meeting.contact);
        } else {
        }
      }
      if (event.event == "onChange" && (event.entityField.full_model_property == "meetings.duration")) {
        //Si se cambia la duración, actualiza el campo 'to' del meeting.
        this.updateDateTo();
      }
      if (event.event == "fieldAction" && event.action.name == "new-mail") {
        var toInfo = { name: "", email: event.value }
        if (event.action.param == "INTERNAL") {
          //Cargamos los datos del usuario
          this.usersService.get(event.value).subscribe({
            next: (data: any) => {
              toInfo = { name: data.name, email: data.email }
              this.newMail(toInfo, "MAIL_INTERNAL", false);
            },
            error: (error: any) => {
              this.messageService.add({ severity: "error", detail: error.error.title });
            }
          });
        } else {
          this.newMail(event.value, "MEETING", true);
        }

      }

      /*if (event.event == "onChange" && event.entityField.model_property == "contact_id") {//Si se cambia la propiedad contact_id del meeting...
        if (this.meeting.contact.id != null) {
          this.onChangeContact(this.meeting.contact);
        } else {
        }
      };*/
      if (event.event == "onChange" && event.entityField.model_property == "organization_id") {//Si se cambia la propiedad organization_id del meeting...
        if (this.meeting.organization_id != null) {
          this.loadDataOrganization(this.meeting.organization_id);
        } else {
          this.meeting.contact_id = null;
        }
      }
    } else if (event.component == "meetings.attachments") {
      if (event.event == "action" && event.action.name == "add") this.viewAttachment(null);
      if (event.event == "action" && event.action.name == "delete") this.deleteAttachment(event.data);
      if (event.event == "dblclick") this.viewAttachment(event.data);
      if (event.event == "action" && event.action.name == "download") this.downloadFile(event.data);
    } else if (event.component == "meetings.campaigns") {
      if (event.event == "action" && event.action.name == "edit") this.openCampaign(event.data);
      if (event.event == "action" && event.action.name == "add") this.addCampaigns();
      if (event.event == "action" && event.action.name == "unlink") this.unlinkCampaign(event.data);
    }
    if (event.component == "view" && event.event == "entity-loaded") {
      ///this.config.header = typeof (this.meeting.id) !== "undefined" ? "Detalles de la " + this.view.getEntityBaseName() : "Nueva " + this.view.getEntityBaseName();
    }
  }
}
