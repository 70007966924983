<ul class="breadcrumb">
    <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}} </span>{{'general.breadcrumb.home' | translate}}</a></li>
    <li *ngIf="meeting.organization_id != null && meeting.organization_id != 0"><i class="pi pi-angle-right"></i></li>
    <li *ngIf="meeting.organization_id != null && meeting.organization_id != 0"><a
            [routerLink]="['/organizations']">{{'organization.entity.name_plural' | translate}}</a></li>
    <li *ngIf="meeting.organization_id != null && meeting.organization_id != 0"><i class="pi pi-angle-right"></i></li>
    <li *ngIf="meeting.organization_id != null && meeting.organization_id != 0"><a [routerLink]="['/organizations', 0, meeting.organization_id]">{{meeting.organization?.name}}</a></li>
    <li><i class="pi pi-angle-right"></i></li>
    <li>
        <a *ngIf="meeting.organization_id == null" [routerLink]="['/calendar']">{{'meeting.entity.name_plural' | translate}}</a>
        <a *ngIf="meeting.organization_id != null" (click)="navigateCalendarFiltered()">{{'meeting.entity.name_plural' | translate}}</a>
    </li>
    <li><i class="pi pi-angle-right"></i></li>
    <li [hidden]="meeting.id==null"><a [routerLink]="['/meetings',meeting.organization_id, meeting.id]">{{meeting.subject}}</a></li>
</ul>
<div class="p-grid">
    <div class=" p-sm-12 p-md-12 p-lg-12 p-xl-6 p-col-12">
        <app-view #view [code]="'meetings.edit'" [model]="meeting" (onEvent)="onEvent($event)"></app-view>
    </div>
    <div class="p-sm-12 p-md-12 p-lg-6 p-xl-3 p-col-12">
        <p-card styleClass="p-card-content-fit">
            <div>
                <div class="p-col p-field">
                  <label for="users">{{'component.organizations.meetings_detail.participants' | translate}}</label>
                    <p-dropdown name="users" [(ngModel)]="user.user" [options]="contacts_options" optionLabel="name"
                        [showClear]="true" appendTo="body"></p-dropdown>
                </div>
                <div class="p-col p-field" *ngIf="user.user?.id==null">
                    <label for="inputUser">Correo electrónico</label>
                    <p-autoComplete name="inputUser" [(ngModel)]="user.external_user" [suggestions]="searchResults"
                        class="topAutoComplete" (completeMethod)="filterSearch($event)" field="text"
                        (onSelect)="addEntitySearch($event)" appendTo="body">
                        <ng-template let-result pTemplate="item">
                            <div class="result-item">
                                <div class="container">
                                    <i class="{{result.icon}}"></i>
                                    <div class="p-ml-2">
                                        <div>{{result.text}}</div>
                                        <div><small>{{result.organization_name}}</small></div>
                                        <div><small>{{result.email}}</small></div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </p-autoComplete>
                    <!--input  type="text" pInputText [(ngModel)]="user.external_user"-->
                </div>
                <div class="p-col p-field">
                  <p-button pButton type="button" (onClick)="addUser()" [disabled]="disabledMeeting">
                    {{'component.organizations.meetings_detail.add_participant' | translate}}
                  </p-button>
                </div>
            </div>
            <!--Tabla Meeting Users-->
            <p-table [value]="meeting.meetingUsers">
                <ng-template pTemplate="header">
                    <tr>
                      <th>{{'component.organizations.meetings_detail.name_email' | translate}}</th>
                        <th width="50"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-index="rowIndex" let-selectedUser>
                  <tr>
                    <td>
                            {{selectedUser.external_user?selectedUser.external_user:selectedUser.user.name }} <br>
                        <small>{{selectedUser.user?.email}}{{selectedUser.email}}</small>
                    </td>
                    <td>
                      <a class="color-danger" (click)="deleteMeetingUser(index)" *ngIf="!disabledMeeting">
                        <fa-icon [icon]="['fas', 'times']"></fa-icon>
                      </a>
                    </td>
                    </tr>
                </ng-template>
            </p-table>
</p-card>
    </div>
    <div class="p-sm-12 p-md-12 p-lg-6 p-xl-3 p-col-12">
        <p-card styleClass="p-card-content-fit" id="calendarMeeting">
            <div>
                <div class="p-col-12 p-d-flex p-justify-end" *ngIf="canOpenCalendar">
                  <button pButton type="button" (click)="openCalendar()">{{'component.organizations.meetings_detail.open_calendar' | translate}}</button>
                </div>
                <app-user-calendar [height]="485" [view]="view" [id]="meeting.id"></app-user-calendar>
            </div>
        </p-card>
    </div>
</div>
<div class="p-d-flex">
    <p-button label="{{'general.action.delete' | translate}}" *ngIf="meeting.id > 0 && deleteMeeting" icon="pi pi-trash" styleClass="p-button-danger" [disabled]="disabledMeeting" (onClick)="delete()">
    </p-button>
    <div class="flex-grow-1"></div>
    <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times" styleClass="p-button-secondary p-button-text" (onClick)="cancel()" [disabled]="disabledMeeting">
    </p-button>
    <p-button label="{{'general.action.save_send' | translate}}" *ngIf="writeMeeting" icon="pi pi-envelope" styleClass="p-ml-2" (onClick)="isDuplied($event, true)" [disabled]="disabledMeeting">
    </p-button>
    <p-button label="{{'general.action.save' | translate}}" icon="pi pi-check" *ngIf="writeMeeting" styleClass="p-ml-2" (onClick)="isDuplied($event, false)" [disabled]="disabledMeeting"></p-button>
</div>
