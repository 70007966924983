import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TasksDetailComponent } from './tasks-detail/tasks-detail.component';
import { TasksListComponent } from './tasks-list/tasks-list.component';

const routes: Routes = [
  { path: "tasks", component: TasksListComponent },
  { path: "tasks/:organization_id", component: TasksListComponent },
  { path: "tasks/:organization_id/:id", component: TasksDetailComponent },
  { path: "tasks/:organization_id/:transaction_id/:id", component: TasksDetailComponent },
  { path: "tasks/:organization_id/:id/:prev_id/:next_id", component: TasksDetailComponent },
  { path: "tasks/:origin/:organization_id/:id/:prev_id/:next_id", component: TasksDetailComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TasksRoutingModule { }
