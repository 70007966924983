import { Component, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MeetingsService } from '../organizations/organizations-detail/organizations-meetings-detail/meetings.service';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';

import { OrganizationsService } from '../organizations/organizations.service';
import { OrganizationsMeetingsDetailComponent } from '../organizations/organizations-detail/organizations-meetings-detail/organizations-meetings-detail.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import esLocale from '@fullcalendar/core/locales/es';
import { UsersService } from '../users/users.service';
import { LoginService } from '../login/login.service';
import { MeetingStatesService } from '../organizations/organizations-detail/organizations-meetings-detail/meeting-states.service';
import { ViewComponent } from '../core/view/view.component';
import { Tooltip, TooltipModule } from 'primeng/tooltip';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'ngx-webstorage';
@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  @Input() public canOpenMeeting: any = true;

  public fieldQuicks: any[] = [];
  public componentFilter: any;
  public hasFilterValue: boolean = false;
  public has_filter_panel: boolean = true;
  public show_filter_panel: boolean = false;
  public show_filter_panel_mobile: boolean = false;

  //Parámetros de pre-filtrado:
  public route_organization_id;
  public organization;

  private calendarViewToolbar: string = "";
  public writeMeeting: boolean = true;
  public userId = null;
  public isSuperAdmin = "false";
  public selectedOrganization: any;
  public selectedOrganizationId: number = null;
  public selectedStateId: number = null;
  public subject: any;
  public organizations: any[];
  public states: any[];
  public selectedUsers: any[];
  public names: any[];
  public users: any[];
  public user: any;
  public connectedUserPermission: any;
  public tooltip_info = {
    show: false,
    description: "",
    showType: "",
    date_from: "",
    date_to: "",
    responsible: "",
    organization: "",
    duration: "",
    state: "",
    allDay: false,
    participants: [],
    backgroundColor: '#ceede6',
    textColor: "black",
  };
  public xPos = "0px";
  public yPos = "0px";

  public events: any[] = [];
  public from_date: Date = null;
  public to_date: Date = null;
  public options: any = {
    locale: esLocale,
    navLinks: true,
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    defaultDate: new Date(),
    scrollTime: '06:00:00',
    scrollTimeReset: true,
    contentHeight: 650,
    headerToolbar: {
      left: 'prev,next',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay'
    },
    eventClick: (e) => {
      //Se activa cuando haces clic en un evento.
      //Recoge valores:
      var event_id = e.event._def.extendedProps.event_id;
      var type = e.event._def.extendedProps.type;

      //Abre un popup u otro dependiendo del tipo de entidad:
      if (type == 'meeting') this.meetingService.get(event_id).subscribe(
        data => {
          var meeting;
          meeting = data;
          this.viewMeeting(meeting, null);
        }
      )
    },
    dateClick: (e) => {
      //Se activa cuando haces clic en una fecha.
      this.viewMeeting(null, e.date);
    },
    datesSet: (e) => {
      this.calendarViewToolbar = e.view.type;
      this.handleDatesRender(e);
    },
    eventDragStart: (e) => {
      this.tooltip_info.show = false;
    },
    eventMouseEnter: (e) => {
      //let container = e.el.parentElement;
      let container = document.getElementById("calendarEvents");
      var rect = container.getBoundingClientRect();
      container.addEventListener("mousemove", (e2) => {
        this.xPos = (e2.clientX + 46 - rect.x) + "px";
        this.yPos = (e2.clientY + 32 - rect.y) + "px";
      });
      this.tooltip_info.description = e.event._def.extendedProps.subject;
      this.tooltip_info.show = true;
      this.tooltip_info.showType = e.event._def.extendedProps.showType;
      this.tooltip_info.date_from = (e.event._def.allDay != true ? e.event._def.extendedProps.date_from : "");
      this.tooltip_info.date_to = e.event._def.extendedProps.date_to;
      this.tooltip_info.responsible = e.event._def.extendedProps.responsible;
      this.tooltip_info.organization = e.event._def.extendedProps.organization;
      this.tooltip_info.duration = (e.event._def.allDay == true ? this.translateService.instant("component.calendar.all_day") : this.getTimeOfEvent(e.event._def.extendedProps.minutes));
      this.tooltip_info.participants = e.event._def.extendedProps.participants;
      this.tooltip_info.state = e.event._def.extendedProps.state;
      this.tooltip_info.allDay = e.event._def.allDay;
      this.tooltip_info.backgroundColor = e.event.backgroundColor;
      this.tooltip_info.textColor = this.lightOrDark(e.event.backgroundColor) == "dark" ? "white" : "black";

    },
    eventMouseLeave: (e) => {
      this.tooltip_info.show = false;
    },
    eventResize: (e) => {
      this.modifyEvent(e.event._def.extendedProps, e.endDelta, "resize");
    },
    eventDrop: (e) => {
      this.modifyEvent(e.event._def.extendedProps, e.delta, "drop");
    },
    slotLabelFormat: {
      hour: '2-digit',
      minute: '2-digit',
      meridiem: false
    },
    eventTimeFormat: {
      hour: '2-digit',
      minute: '2-digit',
      meridiem: false
    },
    eventDidMount: function (info) {
    }
  };
  public inModal: boolean = false;

  constructor(

    @Optional() private dialogRef: DynamicDialogRef,
    public meetingService: MeetingsService,
    public messageService: MessageService,
    public organizationService: OrganizationsService,
    public dialogService: DialogService,
    public route: ActivatedRoute,
    public routerLink: Router,
    private usersService: UsersService,
    public loginService: LoginService,
    public meetingStatesService: MeetingStatesService,
    private translateService: TranslateService,
    public sessionStorage: SessionStorageService
  ) {
    this.writeMeeting = this.loginService.hasPermission("MEETINGS_WRITE");
    this.userId = localStorage.getItem("userId");
    this.isSuperAdmin = localStorage.getItem("isSuperAdmin");
    if (this.route.snapshot.paramMap.has('id')) this.selectedOrganizationId = parseInt(this.route.snapshot.paramMap.get('id'));

    this.createQuickFilters();
  }


  ngOnInit(): void {
    const params = this.route.snapshot.queryParams;
    const organizationId = params.organizationId != null ? parseInt(params.organizationId) : null;
    if (organizationId) {
      this.route_organization_id = organizationId;
      this.selectedOrganizationId = this.route_organization_id;
      this.loadOrganization();
    }
    this.loadUser();
    this.loadOrganizations(null);
    this.loadStates(null);
    if (this.dialogRef == null) {
      this.inModal = true;
    }
  }

  loadUser() {
    this.usersService.get(this.loginService.get("userId")).subscribe({
      next: (data: any) => {
        this.user = data;
        this.selectedUsers = this.user.metadata?.calendarConfig?.selectedUsers;
        if (this.selectedUsers == null || this.selectedUsers.length == 0) this.selectedUsers = [parseInt(localStorage.getItem("userId"))];
        this.loadUsers();
        this.loadMeeting();
      },
      error: (error: any) => this.messageService.add({ closable: false, severity: "error", detail: error.error.title })
    });
  }

  loadMeeting() {

    let parameters = {
      check_invited_users: true, //busca tambien en invitados
      from_date: this.from_date.toISOString(),
      to_date: this.to_date.toISOString()
    };

    // Lo eliminamos, lo sacamos de la sesión
    /*if (this.selectedOrganizationId != null) Object.assign(parameters, { organization_id: this.selectedOrganizationId });
    if (this.selectedStateId != null) Object.assign(parameters, { meeting_state_id: this.selectedStateId });
    if (this.subject != null) Object.assign(parameters, { subject: this.subject });
    */

    var sessionInfo = this.sessionStorage.retrieve("calendar.calendar");
    if (sessionInfo != null) {

      var subject = (sessionInfo.filters.find(m => m.subject) != undefined ? sessionInfo.filters.find(m => m.subject).subject : null);
      var selectedOrganizationId = (sessionInfo.filters.find(m => m.selectedOrganizationId) != undefined ? sessionInfo.filters.find(m => m.selectedOrganizationId).selectedOrganizationId : null);
      var selectedStateId = (sessionInfo.filters.find(m => m.selectedStateId) != undefined ? sessionInfo.filters.find(m => m.selectedStateId).selectedStateId : null);

      if (selectedOrganizationId != null) Object.assign(parameters, { organization_id: selectedOrganizationId });
      if (selectedStateId != null) Object.assign(parameters, { meeting_state_id: selectedStateId });
      if (subject != null && subject != "") Object.assign(parameters, { subject: subject });
    }

    if (this.selectedUsers != null && this.selectedUsers.length > 0) {
      Object.assign(parameters, { usersIds: this.selectedUsers });
    } else {
      Object.assign(parameters, { usersIds: [parseInt(localStorage.getItem("userId"))] });
    }

    this.meetingService.all(parameters).subscribe({
      next: (data: any) => {
        this.events = data.rows.map(m => {
          var title = "";
          if (m.organization?.name != null || m.organization?.tradename) {
            title += "(";
            if (m.organization?.name != null) {
              title += m.organization?.name;
              if (m.organization?.tradename != null) title += ", ";
            }
            if (m.organization?.tradename != null) title += m.organization.tradename;
            title += ")";
          };

          var isCreator = (this.userId == m.created_by);
          var isResponsible = (this.userId == m.responsible_id);
          return {
            minutes: m.duration,
            title: m.subject + " " + title,
            subject: m.subject,
            start: m.from,
            end: m.to,
            event_id: m.id,
            created_by: m.created_by,
            responsible_id: m.responsible_id,
            date_from: m.from,
            date_to: m.to,
            type: 'meeting',
            allDay: m.allDay,
            showType: this.translateService.instant("component.calendar.meeting"),
            responsible: m.responsible?.name,
            organization: (m.organization?.name ? m.organization.name : "") + (m.organization?.tradename ? " (" + m.organization.tradename + ")" : ""),
            duration: m.duration,
            display: 'block',
            backgroundColor: m.responsible.color ? m.responsible.color : "#e8e8e8",
            //textColor: "black",
            textColor: this.lightOrDark(m.responsible.color ? m.responsible.color : "#e8e8e8") == "dark" ? "white" : "black",
            participants: m.meetingUsers,
            state: m.state?.name,
            editable: (this.calendarViewToolbar != "dayGridMonth" && ((this.isSuperAdmin === "true") || this.loginService.canEditModel(m, this.view?.entity?.code)))
          };
        });
      },
      error: (error: any) => this.messageService.add({ closable: false, severity: "error", detail: error.error.title })
    });
    //if (this.selectedUsers) this.loadNames();
  }

  saveSelectedUsers() {
    this.user.metadata["calendarConfig"] = { "selectedUsers": this.selectedUsers };
    this.usersService.save(this.user.id, this.user).subscribe({
      next: (data: any) => {
        this.user = data;
        this.loadMeeting();
      },
      error: (error: any) => this.messageService.add({ closable: false, severity: "error", detail: error.error.title })
    });
  }

  onChangeUser(user) {
    let idx = this.selectedUsers.indexOf(user.id);
    if (idx >= 0) {
      this.selectedUsers.splice(idx, 1);
      user.selected = false;
    } else {
      this.selectedUsers.push(user.id);
      user.selected = true;
    }
    this.saveSelectedUsers();
  }

  lightOrDark(color) {

    // Variables for red, green, blue values
    var r, g, b, hsp;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

      // If RGB --> store the red, green, blue values in separate variables
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

      r = color[1];
      g = color[2];
      b = color[3];
    }
    else {

      // If hex --> Convert it to RGB: http://gist.github.com/983661
      color = +("0x" + color.slice(1).replace(
        color.length < 5 && /./g, '$&$&'));

      r = color >> 16;
      g = color >> 8 & 255;
      b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {

      return 'light';
    }
    else {

      return 'dark';
    }
  }

  getUserCheckClass(user: any) {
    let className = "checkbox-color-black";
    if (user.color) {
      let hexColor = user.color.replace("#", "");
      className = this.lightOrDark(hexColor) == "dark" ? 'checkbox-color-white' : 'checkbox-color-black';
    }
    return "checkbox-no-bg " + className;
  }

  loadOrganizations(event) {
    let params: any = {};
    if (event != null) params.text = event.filter;
    if (this.selectedOrganizationId != null) params.include_organization_id = this.selectedOrganizationId;
    this.organizationService.getCombo(params).subscribe({
      next: (data: any) => {
        this.organizations = data.rows;
        //this.organizations.unshift({ name: "Todas", id: null });
      },
      error: (error: any) => this.messageService.add({ closable: false, severity: "error", detail: error.error.title })
    });

  }

  loadStates(event) {
    let params: any = {};
    if (event != null) params.text = event.filter;
    if (this.selectedStateId != null) params.include_state_id = this.selectedStateId;
    this.meetingStatesService.all({}).subscribe({
      next: (data: any) => {
        this.states = data.rows;
        //this.states.unshift({ name: "Todos", id: null });
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    });
  }

  loadUsers() {

    //si no puedes ver otros calendarios, solo le añadimos a el
    if (!this.loginService.hasPermission("CALENDAR_OTHERS_USERS")) {
      this.selectedUsers = [localStorage.getItem("userId")];
      return;
    }

    //cargamos otros usuarios
    this.meetingService.users({}).subscribe({
      next: (data: any) => {
        this.users = data.rows;
        if (this.selectedUsers != null) {
          this.users.forEach(user => {
            if (this.selectedUsers.indexOf(user.id) >= 0) user.selected = true;
          });
        }
      },
      error: (error: any) => this.messageService.add({ closable: false, severity: "error", detail: error.error.title })
    });
  }
  viewMeeting(meeting, from: null) {
    if (this.canOpenMeeting) {
      var params = {
        id: meeting ? meeting.organization_id : 0,
        contactId: meeting ? meeting.contact_id : 0,
        meetingId: meeting ? meeting.id : 0,
        deactivated: meeting ? true : false,
        from: from ? from : null
      }
      // Utiliza router.navigate y pasa el objeto de parámetros
      this.routerLink.navigate(['/meetings/', (meeting ? meeting.id : 0)], { queryParams: params });
      if (this.dialogRef) {
        //Cerramos el popup.
        this.dialogRef.close();
      }
    }

  }

  viewOrganization(organization: any) {
    this.routerLink.navigate(['/organizations/', organization.organization_type_id ? organization.organization_type_id : 0, organization.id]);
  }

  modifyEvent(props, delta, action) {
    if (props.type == "meeting") this.saveMeeting(props, delta, props.event_id, action);
  }

  calcNewDate(originalDate, delta) {
    var newDate = new Date(originalDate);

    if (delta.years) {
      newDate.setFullYear(newDate.getFullYear() + delta.years);
    }

    if (delta.months) {
      newDate.setMonth(newDate.getMonth() + delta.months);
    }

    if (delta.days) {
      newDate.setDate(newDate.getDate() + delta.days);
    }

    if (delta.milliseconds) {
      newDate.setMilliseconds(newDate.getMilliseconds() + delta.milliseconds);
    }
    return newDate.toISOString();
  }

  calcNewDateTo(date, duration) {
    var dateFrom = new Date(date);
    var dateTo = new Date(dateFrom.getTime() + duration * 60000);
    return dateTo.toISOString();
  }

  calcDuration(date_from, date_to) {
    var startDate = new Date(date_from);
    var endDate = new Date(date_to);
    var duration = endDate.getTime() - startDate.getTime();
    return duration / 60000;
  }


  saveMeeting(props, delta, meeting_id, action) {

    if (action == "drop") {
      var new_date_from = this.calcNewDate(props.date_from, delta);
      var new_date_to = this.calcNewDateTo(new_date_from, props.minutes);
      this.meetingService.setNewDates(meeting_id, { new_date_from: new_date_from, new_date_to: new_date_to, id: meeting_id }).subscribe(
        data => {
          //this.messageService.add({ closable: false, severity: "success", detail: "Evento modificado" });
          this.loadMeeting();
        },
        error => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      );
    }
    if (action == "resize") {
      var new_date_to = this.calcNewDate(props.date_to, delta);
      this.meetingService.setNewDates(meeting_id, { new_date_to: new_date_to, duration: this.calcDuration(props.date_from, new_date_to) }).subscribe({
        next: (data: any) => {
          //this.messageService.add({ closable: false, severity: "success", detail: "Evento modificado" });
          this.loadMeeting();
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    }
  }

  handleDatesRender($event) {
    this.from_date = $event.start;
    this.to_date = $event.end;
    this.loadMeeting();
  }

  getTimeOfEvent(duration) {
    //Éste método devuelve una cadena de texto con la duración del evento en el formato X h XX m.

    var durationText = "";
    if (duration >= 60) {
      var hours = Math.floor(duration / 60);
      var minutes = duration % 60;
      if (hours === 1) {
        //Si se necesita, se puede poner aquí "hora":
        durationText = "1 h";
      } else {
        //Si se necesita, se puede poner aquí "horas":
        durationText = hours + " h";
      }
      if (minutes > 0) {
        durationText += " ";
        if (minutes === 1) {
          //Si se necesita, se puede poner aquí "minuto":
          durationText += "1 min.";
        } else {
          //Si se necesita, se puede poner aquí "minutos":
          durationText += minutes + " min.";
        }
      }
    } else {
      //Si se necesita, se puede poner aquí "minutos":
      durationText = duration + " min.";
    }
    return durationText;
  }


  createQuickFilters() {

    //Los buscamos en la sesión
    var sessionInfo = this.sessionStorage.retrieve("calendar.calendar");
    var selectedFields = [];

    if (sessionInfo != null) {
      this.hasFilterValue = true;
      this.subject = (sessionInfo.filters.find(m => m.subject) != undefined ? sessionInfo.filters.find(m => m.subject).subject : null);
      this.selectedOrganizationId = (sessionInfo.filters.find(m => m.selectedOrganizationId) != undefined ? sessionInfo.filters.find(m => m.selectedOrganizationId).selectedOrganizationId : null);
      this.selectedStateId = (sessionInfo.filters.find(m => m.selectedStateId) != undefined ? sessionInfo.filters.find(m => m.selectedStateId).selectedStateId : null);

      /*      this.subject = sessionInfo.filters["subject"];
            this.selectedOrganizationId = sessionInfo.filters["selectedOrganizationId"];
            this.selectedStateId = sessionInfo.filters["selectedStateId"];*/
    }

  }

  toggleFilter() {
    this.show_filter_panel = !this.show_filter_panel;
  }
  toggleFilterMobile() {
    this.show_filter_panel_mobile = !this.show_filter_panel_mobile;
  }

  resetFilters() {
    if (this.selectedOrganizationId != null) this.selectedOrganizationId = null;
    if (this.selectedStateId != null) this.selectedStateId = null;
    if (this.subject != null) this.subject = null;
    this.sessionStorage.clear("calendar.calendar");
    this.hasFilterValue = false;
    this.loadMeeting();
  }

  loadMeetingFilter() {
    this.sessionStorage.clear("calendar.calendar");
    if (this.subject != null || this.selectedOrganizationId != null || this.selectedStateId != null) {
      this.hasFilterValue = true;
      var sessionInfo = { filters: [] };
      /*sessionInfo.filters.push({ organization_id: this.route_organization_id });
      sessionInfo.filters.push({ include_organization_id: this.route_organization_id });
      sessionInfo.filters.push({ _op_organization_id: "in" });
      sessionInfo.filters.push({ _ignore_session_fields: this.route_organization_id });
      sessionInfo.filters.push({ _excluded_fields: this.route_organization_id });*/
      if (this.subject != null) sessionInfo.filters.push({ subject: this.subject });
      if (this.selectedOrganizationId != null) sessionInfo.filters.push({ selectedOrganizationId: this.selectedOrganizationId });
      if (this.selectedStateId != null) sessionInfo.filters.push({ selectedStateId: this.selectedStateId });
      this.sessionStorage.store("calendar.calendar", sessionInfo);
    } else {
      this.hasFilterValue = false;
    }
    this.loadMeeting();

  }
  loadOrganization() {
    this.organizationService.get(this.route_organization_id).subscribe({
      next: (data: any) => {
        this.organization = data;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }
}
