<div class="page-title">{{view.getEntityBaseName(true)}}</div>
<ul class="breadcrumb">
  <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}}
      </span>{{'general.breadcrumb.home' | translate}}</a></li>
  <li *ngIf="organization != null"><i class="pi pi-angle-right"></i></li>
  <li *ngIf="organization != null"><a [routerLink]="['/organizations/0',organization.id]">{{organization.name}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li><a [routerLink]="['/tasks']">{{'task.entity.name_plural' | translate}} <!--{{view.getEntityBaseName()}}--></a>
  </li>
</ul>
<app-view #view [code]="'tasks.list'" [model]="tasksFilters" (onEvent)="onEvent($event)"></app-view>