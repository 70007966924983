<p-card styleClass="p-mt-4 p-card-content-fit">
  <ng-template pTemplate="header">
    <div class="p-card-title" *ngIf="showConfigOptions || !component?.filters?.allow_filter_panel">
      {{componentService.getComponentTitle(component)}}
      <small *ngIf="showConfigOptions|| debugService.enabled">Component: {{component.code}}, Entity:
        {{component.entity}}</small>
      <div *ngIf="!showConfigOptions && !component?.filters?.allow_filter_panel" class="p-card-title-actions">
        <ng-container *ngFor="let action of component.headerActions">
          <a *ngIf="isActionVisible(action, item) && !locked" (click)="onActionClick(action, item, $event)"
            class="{{action.class}}">
            <fa-icon *ngIf="action.icon!=null" [icon]="action.icon"></fa-icon>
            <span>{{getActionLabel(action, item)}}</span>
            <br *ngIf="debugService.enabled" /><small
              *ngIf="debugService.enabled">{{component.code}}.{{action.name}}</small>
          </a>
        </ng-container>
        <a (click)="exportExcel()"
          *ngIf="component.allow_export_xls && this.loginService.hasPermission('EXPORT_EXCEL')">
          <fa-icon [icon]="['fas', 'file-excel']"></fa-icon>
        </a>
      </div>
      <div *ngIf="showConfigOptions" class="p-card-title-actions">
        <ng-content select="[toolbarStart]"></ng-content>
        <a (click)="showColumnsSelector($event)" class="internal-action">
          <fa-icon [icon]="['fas', 'columns']"></fa-icon>
        </a>
        <ng-content select="[toolbarEnd]"></ng-content>
      </div>
    </div>
  </ng-template>

  <div class="p-d-flex table-container p-ai-start">
    <div class="flex-grow-1" [style.height]="component.height" [class.with-filter-panel]="show_filter_panel">

      <!-- tabla normal -->
      <p-table #grid styleClass="no-mobile" [lazy]="true" (onLazyLoad)="preLazyLoad($event)" [value]="rows"
        [loading]="loading" [paginator]="component.show_header" [totalRecords]="totalRows"
        [showCurrentPageReport]="component.show_header"
        currentPageReportTemplate="{{ 'general.resumen_table_records' | translate: { first: first, last: last, totalRecords: totalRows } }}"
        [rowsPerPageOptions]="rowsPerPageOptions" [rows]="rowsPerPage" [scrollable]="true"
        scrollHeight="{{component.height!=null?component.height:'flex'}}" scrollDirection="both"
        (onRowReorder)="onRowReorder($event)" paginatorDropdownAppendTo="body" [reorderableColumns]="showConfigOptions"
        [resizableColumns]="showConfigOptions" [columnResizeMode]="columnResizeMode"
        (onColReorder)="onColReorder($event)" (onColResize)="onColResize($event)" [(selection)]="selectedRow"
        selectionMode="multiple">
        <ng-template pTemplate="header">

          <tr [hidden]="!component.show_header && !showConfigOptions">
            <th style="width: 35px" *ngIf="component.checked_multiple?.allow">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th *ngIf="component.allow_reorder_rows" style="width:35px"></th>
            <th pReorderableColumn pResizableColumn *ngFor="let field of component.fields; let i = index"
              [attr.data-index]="i" [pSortableColumn]="field.entityField?.model_property"
              [pSortableColumnDisabled]="showConfigOptions"
              [hidden]="!showConfigOptions && !componentService.isVisibleField(field, model)">
              <a *ngIf="showConfigOptions" class="entity-field-configure" (click)="configField($event, field)">
                <i class="fas fa-cog"></i>
              </a>
              {{getFieldDescription(field)}}
            </th>
            <th *ngIf="component.actions.length>0"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-index="rowIndex">
          <tr (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)"
            (touchend)="onTouchEnd(item, $event)" (click)="onDblClick(item, $event)" [pReorderableRow]="index"
            class="{{getRowClass(item)}}">

            <td *ngIf="component.checked_multiple?.allow" style="width:35px">
              <p-tableCheckbox [value]="item" (click)="selectedRowTable(item, $event)"></p-tableCheckbox>
            </td>
            <td *ngIf="component.allow_reorder_rows" style="width:35px"><span class="pi pi-bars"
                pReorderableRowHandle></span></td>
            <td *ngFor="let field of component.fields" [hidden]="!componentService.isVisibleField(field, model)"
              [title]="getRowValue(item, field,false, true)" [innerHTML]="getRowValue(item, field)"
              [style.width.px]="field.width" [style.maxWidth.px]="field.width" [style.font-weight]="field.bold_in_cell == true?'bold':'normal'">
            </td>
            <td class="grid-actions" *ngIf="component.actions.length>0">
              <div>
                <span *ngFor="let action of component.actions">
                  <a (click)="onActionClick(action, item, $event)" class="{{action.class}}"
                    *ngIf="isActionVisible(action, item) && !locked">
                    <fa-icon *ngIf="action.icon!=null" [icon]="action.icon"></fa-icon>
                  </a>
                </span>
              </div>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td [attr.colspan]="colspanIfEmpty" [innerHTML]="getHTMLIfEmpty()" style="border: 0px">
            </td>
          </tr>
        </ng-template>
      </p-table>

      <!-- tabla de mobiles -->
      <p-table #grid styleClass="mobile" [lazy]="true" (onLazyLoad)="preLazyLoad($event)" [value]="rows"
        [loading]="loading" [paginator]="component.show_header" [totalRecords]="totalRows"
        [showCurrentPageReport]="component.show_header"
        currentPageReportTemplate="{{ 'general.resumen_table_records' | translate: { first: first, last: last, totalRecords: totalRows } }}"
        [rowsPerPageOptions]="rowsPerPageOptions" [rows]="rowsPerPage" [scrollable]="true" scrollHeight="flex"
        ascrollDirection="both" (onRowReorder)="onRowReorder($event)" paginatorDropdownAppendTo="body"
        [reorderableColumns]="showConfigOptions" [resizableColumns]="showConfigOptions"
        [columnResizeMode]="columnResizeMode" (onColReorder)="onColReorder($event)" (onColResize)="onColResize($event)">
        <ng-template pTemplate="body" let-item let-index="rowIndex">
          <tr (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)"
            (touchend)="onTouchEnd(item, $event)" (click)="onDblClick(item, $event)" [pReorderableRow]="index"
            class="{{getRowClass(item)}}">
            <td class="mobile" width="100%">
              <div class="p-grid">
                <div *ngFor="let field of component.fields" [hidden]="field.show_in_mobile!==true"
                  class="field p-col-12 p-grid p-ai-center">
                  <label class="p-col-5 p-pt-0 p-pb-0 column-name">{{getFieldDescription(field)}}</label>
                  <label class="p-col-7 p-pt-0 p-pb-0" [innerHTML]="getRowValue(item, field)"></label>
                </div>
              </div>
              <div [ngClass]="component.actions.length>0?'grid-actions p-mt-3 p-mb-3':'grid-actions'">
                <div>
                  <span *ngFor="let action of component.actions">
                    <a (click)="onActionClick(action, item, $event)" class="{{action.class}} p-ml-2 p-mr-2"
                      *ngIf="isActionVisible(action, item) && !locked">
                      <fa-icon *ngIf="action.icon!=null" [icon]="action.icon"></fa-icon>
                    </a>
                  </span>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td [attr.colspan]="component.fields.length+1" [innerHTML]="getHTMLIfEmpty()" style="border: 0px">
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

  </div>
</p-card>


<p-overlayPanel appendTo="body" #opColumns [style]="{ width: '300px' }">
  <ng-template pTemplate>
    <p-accordion>
      <p-accordionTab *ngFor="let allowedEntity of allowedEntities">
        <ng-template pTemplate="header">{{componentService.getEntityName(allowedEntity)}}</ng-template>
        <ng-template pTemplate="content">
          <!--<p-scrollPanel [style]="{width: '100%', height: '200px'}" *ngIf="allowedEntitiesFields[allowedEntity.code]">-->
            <div style="width: 100%; height: 200px; overflow: auto">
              <div *ngFor="let field of allowedEntitiesFields[allowedEntity.code]">
                <div *ngIf="componentService.isVisibleFieldCustom({ entityField: field }, null)" class="p-d-flex p-m-2">
                  <p-checkbox [binary]="true" [(ngModel)]="field.selected"
                              label="{{componentService.getFieldDescription(field, false)}}"
                              (onChange)="onFieldChange($event, field)"></p-checkbox>

                </div>
              </div>
            </div>
          <!--</p-scrollPanel>-->
        </ng-template>
      </p-accordionTab>
    </p-accordion>
  </ng-template>
</p-overlayPanel>


<p-overlayPanel appendTo="body" #opField>
  <ng-template pTemplate>
    <div class="p-field">
      <label>{{'view.configure.title' | translate}}</label>
      <div class="p-inputgroup">
        <input pInputText [(ngModel)]="selectedField.label" [placeholder]="selectedField.entityField?.description" />
      </div>
    </div>
    <div class="p-field">
      <p-checkbox [(ngModel)]="selectedField.show_in_mobile" [binary]="true"
        label="{{'view.configure.show_mobile' | translate}}"></p-checkbox>
    </div>
    <div class="p-field">
      <label>{{'view.configure.visible_by' | translate}}</label>
      <div class="p-inputgroup">
        <p-dropdown name="visible_by" [(ngModel)]="selectedField.visible_by" [options]="visibleByOptions"
          optionValue="code" optionLabel="label" appendTo="body"></p-dropdown>
      </div>
    </div>
    <div class="p-grid p-mt-4 p-jc-center">
      <button pButton type="button" (click)="removeField(selectedField.entity_field_id)"
        class="p-button-danger p-button-text">
        <fa-icon [icon]="['far','trash-alt']"></fa-icon>&nbsp;{{'view.configure.delete_field' | translate}}
      </button>
    </div>
  </ng-template>
</p-overlayPanel>
