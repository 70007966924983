<div *ngIf="isViewInit" class="page-title">{{view.getEntityBaseName(true)}}</div>
<div *ngIf="transaction_type_id==null" class="page-title">{{'transaction.entity.name_plural' | translate}}</div>

<ul class="breadcrumb">
  <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}}
      </span>{{'general.breadcrumb.home' | translate}}</a></li>
  <li *ngIf="organization != null"><i class="pi pi-angle-right"></i></li>
  <li *ngIf="organization != null"><a [routerLink]="['/organizations/0',organization.id]">{{organization.name}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li *ngIf="isViewInit"><a [routerLink]="['/transactions/'+transaction_type_id]">{{view.getEntityBaseName(true)}}</a>
  </li>
  <li *ngIf="transaction_type_id==null"><a [routerLink]="['/transactions']">{{'transaction.entity.name_plural' |
      translate}}</a></li>
</ul>
<app-view *ngIf="transaction_type_id==null" #view [code]="'transactions.list'" [model]="transactionsFilters"
  (onEvent)="onEvent($event)"></app-view>
<app-view *ngIf="transaction_type_id!=null" #view [code]="'transactions.list'+transaction_type_id"
  [model]="transactionsFilters" (onEvent)="onEvent($event)"></app-view>