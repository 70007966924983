import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { NgEventBus } from 'ng-event-bus';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AttachmentsDataService } from 'src/app/attachments/attachments-data.service';
import { AttachmentsService } from 'src/app/attachments/attachments.service';
import { LoginService } from 'src/app/login/login.service';
import { MailingDetailComponent } from 'src/app/mailing/mailing-detail/mailing-detail.component';
import { OrganizationsDetailGeneralComponent } from 'src/app/organizations/organizations-detail/organizations-detail-general/organizations-detail-general.component';
import { OrganizationsQuickComponent } from 'src/app/organizations/organizations-quick/organizations-quick/organizations-quick.component';
import { TasksDetailComponent } from 'src/app/tasks/tasks-detail/tasks-detail.component';
import { TransactionsDetailComponent } from 'src/app/transactions/transactions-detail/transactions-detail.component';
import { UserChangePasswordComponent } from 'src/app/users/user-change-password/user-change-password.component';
import { UsersService } from 'src/app/users/users.service';
import { ConfigurationService } from '../../configuration/configuration.service';
import { EntitiesService } from '../../configuration/entities.service';
import { UsersDetailComponent } from '../../users/users-detail/users-detail.component';
import { CoreDialogService } from '../dialogs/core-dialog.service';
import { SearchService } from '../search.service';
import { ViewsService } from '../view/views.service';
import { NotificationsService } from 'src/app/notifications/notifications.service';
import { ContactsService } from '../../organizations/organizations-detail/organizations-contacts-detail/contacts.service';
import { UtilsService } from '../utils.service';
import { Office365Service } from 'src/app/configuration/integration/office365.service';
import { SessionStorageService } from 'ngx-webstorage';
import { TranslateService } from '@ngx-translate/core';
import { GMailService } from 'src/app/configuration/integration/gmail.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-topbar',
  templateUrl: 'topbar.component.html',
  styleUrls: ['topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  public itemsQuickMenu: any[] = [];
  public searchResults: any[] = [];
  public alertsNotifications: any[] = [];
  public searchText: string;

  public entity: any = null;
  public dropdownViews: any[] = [];
  public layout: any[] = [];

  private timeout: any = null;

  public created_by: any;
  public user: any = {

  };
  public company: any = {};
  public companyCode;
  public items: any;
  public imageUrl = "assets/img/no-user.jpeg";
  public views_superadmin: boolean;
  public personalize_interface: boolean;
  public personalized_views_read: boolean;
  public personalized_views_write: boolean;
  public allowViewPersonalize: boolean = false;
  public allowedLayouts: any[] = [
    { code: "12", name: "1 " + this.translateService.instant("view.configure.column") + " (100%)" },
    { code: "6-6", name: "2 " + this.translateService.instant("view.configure.columns") + " (50%, 50%)" },
    { code: "8-4", name: "2 " + this.translateService.instant("view.configure.columns") + " (66%, 34%)" },
    { code: "9-3", name: "2 " + this.translateService.instant("view.configure.columns") + " (75%, 25%)" },
    { code: "4-8", name: "2 " + this.translateService.instant("view.configure.columns") + " (34%, 66%)" },
    { code: "3-9", name: "2 " + this.translateService.instant("view.configure.columns") + " (25%, 75%)" },
    { code: "4-4-4", name: "3 " + this.translateService.instant("view.configure.columns") + " (34%, 33%, 33%)" }
  ];

  //Correos entrantes pendientes:
  public totalNotificationsIn: number;
  public totalAlerts: number = 0;

  public alerts: number;

  private office365AlertShowed: boolean = false;
  private gmailAlertShowed: boolean = false;
  public view: any = {};
  public selectedView: any;
  @Input("code") public code: string;
  @Output() onEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild("op") op: OverlayPanel;

  //@Input("model") public model: any = {};
  public flags: any[] = [];
  public flag_code: any = {};
  public showFlag: boolean = false;
  constructor(
    private searchService: SearchService,
    private loginService: LoginService,
    private usersService: UsersService,
    private attachmentsService: AttachmentsService,
    private attachmentsDataService: AttachmentsDataService,
    private coreDialogService: CoreDialogService,
    private router: Router,
    private dialogService: DialogService,
    private messageService: MessageService,
    private eventBus: NgEventBus,
    private viewsService: ViewsService,
    private entitiesService: EntitiesService,
    private configurationService: ConfigurationService,
    private notificationsService: NotificationsService,
    private contactsService: ContactsService,
    private utilsService: UtilsService,
    private office365Service: Office365Service,
    private sessionStorage: SessionStorageService,
    private translateService: TranslateService,
    private gmailService: GMailService
  ) {
    this.flag_code = { code: this.loginService.get("language") };

    //Recojo el identificador del usuario logeado:
    this.created_by = this.loginService.get("userId");
    this.loginService.onLoginStateChange.subscribe(authenticated => {
      if (authenticated) {
        this.loadProfileMenu();
        this.loadQuickMenu();
        if (this.loginService.hasPermission("APP_LANGUAGE_SELECTOR_VIEW")) {
          this.loadLanguages();
        }
        this.loadLoginData(authenticated);
      } else {
        //si ha salido, y hay un timeout lo quitamos
        if (this.timeout != null) {
          clearTimeout(this.timeout);
          this.timeout = null;
        }
      }
    });
    this.utilsService.alertsChange.subscribe(authenticated => {
      this.loadAlerts();
    });

    this.router.events.subscribe(event => {

      if (event instanceof NavigationStart) {
        //ocultamos que se muestre personalización
        Promise.resolve(null).then(() => { this.allowViewPersonalize = false });
      }
    });
    this.viewsService.onViewChange.subscribe(
      data => {
        this.code = data;
        this.viewsService.getViewConfiguration(this.code).subscribe({
          next: (data: any) => {
            this.view = data;
            this.selectedView = this.view.id;
            this.loadViewsDropdown();

            //permitimos que se muestre personalización porque la pantalla tiene vistas, y porque no esta deshabilitada la personalización de ella
            if (this.view.configuration.personalizable != false) {
              Promise.resolve(null).then(() => { this.allowViewPersonalize = true });
            } else {
              Promise.resolve(null).then(() => { this.allowViewPersonalize = false });
            }
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
          }
        });
      });
  }

  loadLanguages() {
    this.usersService.allLanguages({}).subscribe({
      next: (data: any) => {
        this.flags = [];
        data.rows.forEach((item: any) => {
          this.flags.push({
            name: item.language.name,
            flag_code: item.language.flag_code,
            code: item.language.iso_code
          });
        });
        //Para seleccionar la bandera.
        if (this.flag_code != null && this.flag_code.code) {
          this.flag_code.flag_code = this.flags.filter(m => m.code == this.flag_code.code)[0].flag_code;
        }
        if (this.flags.length > 1) this.showFlag = true;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  changeLanguage() {
    localStorage.setItem("language", this.flag_code.code);
    this.translateService.reloadLang(this.flag_code.code).subscribe((value: any) => {
      this.translateService.use(this.flag_code.code).subscribe((value: any) => {
        location.reload();
      });
    });
  }

  ngOnInit(): void {
  }

  loadQuickMenu() {

    this.itemsQuickMenu = [
      /* {
         label: 'Añadir contacto',
         icon: 'pi pi-user',
         //routerLink: this.addUser,
         command: (event) => { this.addContact(); },
         visible: this.loginService.hasPermission("CONTACTS_WRITE")
       },*/
      {
        label: this.translateService.instant("general.toolbar.add_organization"), //'Añadir organización',
        icon: 'pi pi-fw pi-briefcase',
        command: (event) => { this.addOrganization(); },
        visible: this.loginService.hasPermission("ORGANIZATIONS_WRITE"),
        value: 0
      },
      {
        label: this.translateService.instant("general.toolbar.new_email"), //'Nuevo email',
        icon: 'pi pi-fw pi-envelope',
        command: (event) => { this.addEmail(); },
        visible: true,
        value: 0
      },
      {
        label: this.translateService.instant("general.toolbar.new_report"), //'Nuevo informe',
        icon: 'pi pi-fw pi-copy',
        routerLink: ['reports/0'],
        visible: this.loginService.hasPermission("REPORTS_WRITE"),
        value: 0
      }, {
        label: this.translateService.instant("general.toolbar.new_oportunity"), //'Crear oportunidad',
        icon: 'pi pi-fw pi-money-bill',
        command: (event) => { this.addOportunity(); },
        visible: this.loginService.hasPermission("OPORTUNITIES_WRITE"),
        value: 0
      },
      {
        label: this.translateService.instant("general.toolbar.new_task"), //'Crear actividad',
        icon: 'pi pi-fw pi-list',
        command: (event) => { this.addTask(); },
        visible: this.loginService.hasPermission("TASKS_WRITE"),
        value: 0
      },
      {
        label: this.translateService.instant("general.toolbar.see_calendar"), //'Ver calendario',
        icon: 'pi pi-fw pi-calendar',
        routerLink: ['/calendar'],
        visible: this.loginService.hasPermission("CALENDAR"),
        value: 0
      },/*
      {
        label: 'Alertas',
        icon: 'pi pi-fw pi-bell',
        routerLink: ['/home'],
        visible: true,
        value: 1
      },*/
    ];

    //cargamos info de login
    //no cargamos hasta que no se produca un cambio de estado de login
    /*setTimeout(()=>{
      this.loadLoginData(false);
    }, 500);*/
  }

  loadProfileMenu() {
    this.items = [
      {
        label: this.translateService.instant("general.toolbar.profile"), //'Ver perfil',
        icon: 'pi pi-fw pi-user',
        command: (event) => { this.viewUser(); },
        //TODO: desconectar al usuario.
      },
      {
        label: this.translateService.instant("general.toolbar.change_password"),//'Cambiar contraseña', 
        command: (event) => { this.changePassword(); },
        icon: 'pi pi-fw pi-key',
        //event.originalEvent: Browser event
        //event.item: menuitem metadata
      },
      {
        label: this.translateService.instant("general.toolbar.close_session"), //'Cerrar sesión',
        icon: 'pi pi-fw pi-sign-out',
        command: (event) => { this.logout(); },
        //TODO: desconectar al usuario.
      }
    ];
    this.personalize_interface = this.loginService.hasPermission("PERSONALIZE_INTERFACE");
    this.personalized_views_read = this.loginService.hasPermission("PERSONALIZED_VIEWS_READ");
    this.personalized_views_write = this.loginService.hasPermission("PERSONALIZED_VIEWS_WRITE");
    this.views_superadmin = this.loginService.hasPermission("VIEWS_SUPERADMIN") || localStorage.getItem("isSuperAdmin") == "true";
  }

  viewUser() {
    const ref = this.dialogService.open(UsersDetailComponent, {
      data: {
        id: localStorage.getItem("userId")
      },
      //header: user ? "Detalles del usuario" : "Nuevo usuario",
      width: '70%',
    });
    ref.onClose.subscribe((data) => {
      //TODO: Recargar dropdown
    });
  }

  logout(): void {
    this.coreDialogService.confirm({
      message: this.translateService.instant("general.toolbar.logout_confirmation"), //"¿Está seguro que desea cerrar la sesión?",
      header: "",
      icon: "pi pi-info-circle",
      accept: () => {
        this.loginService.logout();
      },
      reject: () => { }
    });
  }
  changePassword() {
    const ref = this.dialogService.open(UserChangePasswordComponent, {
      data: {
        id: [localStorage.getItem("userId")]
      },
      header: this.translateService.instant("general.toolbar.change_password"), //"Cambiar contraseña",
      width: "40%",
      /*baseZIndex: 1000*/
    });
    ref.onClose.subscribe((data: any) => {
      if (data !== undefined) {
      } else if (localStorage.getItem("forgotPassword") == "true") {
        //new_password pasa a ser password en caso de no llegar a hacer el cambio de contraseña:
        this.setNewPassword();
      }
    });
  }

  setNewPassword() {
    this.usersService.setNewPassword().subscribe({
      next: (data: any) => {
        localStorage.removeItem("forgotPassword");
        alert("La contraseña pasó a ser la contraseña de recuperación.");
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  filterSearch(event) {
    let query = event.query;
    this.searchService.all({ search_text: query }).subscribe({
      next: (data: any) => {
        this.searchResults = [...data];
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  loadLoginData(authenticated) {
    this.user.id = localStorage.getItem("userId");
    this.user.name = localStorage.getItem("name");
    this.user.email = localStorage.getItem("email");
    this.user.companyName = localStorage.getItem("companyName");
    //this.user.isSuperAdmin = localStorage.getItem("isSuperAdmin");
    this.companyCode = localStorage.getItem("companyCode");
    if (localStorage.getItem("forgotPassword") === "true") this.changePassword();

    //si esta logado, cargamos la información
    if (this.loginService.isLoggedIn()) {
      this.loadCompanyInfo();
      this.loadProfile();
      this.loadAlerts();
    }
  }

  loadCompanyInfo() {
    this.configurationService.company().subscribe({
      next: (data: any) => {
        this.company = data;
        localStorage.setItem("is_template", data.is_template);
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    })
  }

  viewElementDetail(event) {
    this.searchText = "";
    if (event.entity_type == "contacts") {
      this.router.navigate(["/contacts/" + (event.id)]);
    } else if (event.entity_type == "leads") {
      this.router.navigate(["/leads/" + (event.id)]);
    } else if (event.entity_type == "tasks") {
      this.router.navigate(["/" + event.entity_type + '/', 0, event.id]);
      /*
      const ref = this.dialogService.open(TasksDetailComponent, {
        data: {
          //id:
          id: event.id
        },
        width: '70%',
      })*/
    } else {
      this.router.navigate(['/' + event.entity_type + '/', 0, event.id]);
    }
  }

  toogleMenu() {
    this.eventBus.cast('sidebar:toogle', null);
  }

  quickAddOrganization() {
    //le pasamos la vista en la que está para poder utilizarla para recargar la lista de las vistas
    const ref = this.dialogService.open(OrganizationsQuickComponent, {
      data: {
        id: 0
      },
      width: '70%'
    });
    ref.onClose.subscribe((data: any) => {
    })
  };

  loadProfile() {
    if (this.user.id && this.loginService.hasPermission("APP_LOGIN") && this.loginService.isLoggedIn()) {
      this.attachmentsDataService.getProfile({ entity_id: 18, entity_pk_id: this.user.id }).subscribe({
        next: (data: any) => {
          if (data != null && data['b64']) this.imageUrl = 'data:image/jpeg;base64,' + data['b64'];
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        }
      });
    }
  }

  loadBaseEntity() {
    this.entitiesService.getByCode(this.view.configuration.entity).subscribe({
      next: (data: any) => {
        this.entity = data;
        this.onEvent.emit({ component: "view", event: "entity-loaded", view: this.view, entity: this.entity });
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  prepareLayout() {
    let cols = this.view?.configuration?.layout.split("-");
    let nCol = 0;
    this.layout = [];
    cols.forEach(col => {
      this.layout.push({
        id: nCol,
        class: "p-md-" + col,
        components: this.getComponentsForColumn(nCol)
      });
      nCol++;
    });
    //this.onEvent.emit({ component: "view", event: "prepare-layout", view: this.view, entity: this.entity });
  }

  editViewClick(createNew) {
    this.viewsService.editView(this.code);
    this.op.hide();
  }
  newView() {
    this.viewsService.createView();
  }

  onLayoutChange(event) {
    let totalCols = this.view.configuration.layout.split("-").length;
    //si hay componentes sin col, los mandamos a la primera
    this.view.configuration.components.forEach(component => {
      if (component.layout_col != null && component.layout_col > totalCols - 1) component.layout_col = 0;
    });
    this.prepareLayout();
  }

  getComponentsForColumn(nCol: number) {
    let components = this.view?.configuration?.components.filter(m => {
      return m.layout_col == nCol || (nCol == 0 && m.layout_col == null);
    });
    return components;
  }
  loadViewsDropdown() {
    this.viewsService.viewsCombo({ code: this.code }).subscribe({
      next: (data: any) => {
        this.dropdownViews = data;
        /*if (this.personalized_views_write) {
          this.dropdownViews.push({ id: -1, name: "*** Crear nueva vista ***" });
        }*/
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }
  changeCurrentView() {
    //Éste método utilizará el id de la view seleccionada (selectedView) para guardarlo en el metadata del usuario (userId).
    if (this.selectedView != -1) {
      this.usersService.changeCurrentView(this.loginService.get("userId"), this.selectedView).subscribe({
        next: (data: any) => {
          if (data) {
            this.viewsService.removeViewConfigurationFromCache(this.selectedView.code);
            location.reload();
          }
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    }
    //Eliminaremos la sesion de la vista para que la recargue con la información de la nueva vista.
    this.sessionStorage.clear(this.view.code);
  }

  addOrganization() {
    const ref = this.dialogService.open(OrganizationsDetailGeneralComponent, {
      data: {
        id: 0,
        organization_type_id: null
      },
      width: '70%'
    });
    ref.onClose.subscribe((data: any) => {
      this.router.navigate(['/organizations/', data.organization_type_id ? data.organization_type_id : 0, data.id]);
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      this.usersService.visitedOrganizations(data.id).subscribe(data2 => {
        this.view.fireEvent("home.organization", "reload");
      },
        error => {
          console.log(error);
        });
    })
  }
  addEmail() {
    const ref = this.dialogService.open(MailingDetailComponent, {
      data: {
        to: null,
        //template_type_id: 1,
        template_type_code: "MAIL",
        is_generic: true,
        searchRelated: true
      },
      header: this.translateService.instant("general.new_mail_title"),
      width: '70%',
    });
    ref.onClose.subscribe((data) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
    });
  }
  addContact() {
    this.router.navigate(["/contacts/0"]);
  }
  addOportunity() {
    const ref = this.dialogService.open(TransactionsDetailComponent, {
      data: {
        id: 0,// opportunity ? opportunity.id : 0,
        organization_id: null,// this.organization.id,
        transaction_type_id: 1
      },
      width: '70%'
    });
    ref.onClose.subscribe((data: any) => {
      if (data != null) {
        this.router.navigate(['/transactions', data.transaction_type_id, data.id]);
      } else {
        this.view.fireEvent("home.opportunities", "reload");
        if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      }

    });
  }

  addTask() {
    const ref = this.dialogService.open(TasksDetailComponent, {
      data: {
        id: 0
      },
      width: '70%'
    });
    ref.onClose.subscribe((data: any) => {

      if (data != null) {
        this.router.navigate(['/tasks/', (data.organization_id ? data.organization_id : 0), (data?.id ? data.id : 0)]);
      } else {
        this.view.fireEvent("home.tasks", "reload");
        if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      }

    })
  }

  call(item) {
    if (typeof item.routerLink != "undefined") {
      //window.location.replace(item.routerLink);
      this.router.navigate(item.routerLink);
    } else {
      item.command();
    }

  }

  loadAlerts() {
    this.alertsNotifications = [];
    this.totalAlerts = 0;

    this.loadInboundMailsNotifications();
    this.loadLeadsNotifications();
    this.loadOffice365ConfigNotification();
    this.loadGMailConfigNotification();

    //cada 30 segundos, si hay timer de antes lo limpieamos
    if (this.timeout != null) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    this.timeout = setTimeout(() => {
      this.loadAlerts();
    }, 30 * 1000);
  }

  async loadInboundMailsNotifications() {
    var params = {
      direction: "in",
      _op_direction: "like",
      status: "pending",
      _op_status: "like",
      type: "email",
      _op_type: "like",
    }

    //Carga el número de correos entrantes que estén en estado pendiente:
    let data = await firstValueFrom(this.notificationsService.all(params));
    //this.notificationsService.all(params).subscribe({
    //  next: (data: any) => {
    if (data.totalRows > 0) {
      var aux = this.alertsNotifications.find(m => m.code == "notifications-in");
      if (aux == null) {
        this.alertsNotifications.push({
          code: "notifications-in",
          label: '<span class="p-badge p-badge-danger alertsTopbarBadge">' + data.totalRows + '</span>&nbsp;&nbsp;Correos entrantes',
          icon: 'pi pi-fw pi-at',
          routerLink: ['notifications-in'],
          visible: true,
          value: data.totalRows
        });
      } else {
        aux.value = data.totalRows;
        aux.label = '<span class="p-badge p-badge-danger alertsTopbarBadge">' + data.totalRows + '</span>&nbsp;&nbsp;Correos entrantes';
      }
    } else {
      delete this.alertsNotifications[this.alertsNotifications.findIndex(m => m.code == "notifications-in")];
    }
    this.totalAlerts += data.totalRows;
    //  },
    //  error: (error: any) => { }
    //});
  }

  async loadLeadsNotifications() {
    if (this.loginService.hasPermission("LEADS_READ")) {
      //carga leads pendientes de procesar
      let total = await firstValueFrom(this.contactsService.getAllLeads());
      //this.contactsService.getAllLeads().subscribe({
      //  next: (total: any) => {
      this.totalAlerts += total;

      if (total > 0) {
        var aux = this.alertsNotifications.find(m => m.code == "leads");
        if (aux == null) {
          this.alertsNotifications.push({
            code: "leads",
            label: '<span class="p-badge p-badge-danger alertsTopbarBadge"> ' + total + '</span>&nbsp;&nbsp;Leads',
            icon: 'pi pi-fw pi-user',
            routerLink: ['leads'],
            visible: true,
            value: total
          });
        } else {
          aux.value = total;
          aux.label = '<span class="p-badge p-badge-danger alertsTopbarBadge" >' + total + '</span>&nbsp;&nbsp;Leads';
        }
      } else {
        delete this.alertsNotifications[this.alertsNotifications.findIndex(m => m.code == "leads")];
      }
      //  },
      //  error: (error: any) => { }
      //});
    }
  }

  async loadOffice365ConfigNotification() {
    //comprueba si tiene pendiente conectar su cuenta con office365
    let data: any = await firstValueFrom(this.office365Service.me());
    //this.office365Service.me().subscribe({
    //  next: (data: any) => {
    if (data != null) {
      let requireConnection: boolean = false;
      if (data.mail.enabled) requireConnection = true;
      if (data.mail.allow_send) requireConnection = true;
      if (data.calendar.enabled) requireConnection = true;
      if (data.contacts.enabled) requireConnection = true;
      if (!data.connected && requireConnection) {
        this.totalAlerts += 1;
        this.alertsNotifications.push({
          code: "office365",
          label: '<span class="p-badge p-badge-danger alertsTopbarBadge">1</span>&nbsp;&nbsp;Office 365',
          icon: 'pi pi-fw pi-cog',
          command: (event) => {
            //abrir configurador
            this.configOffice365();
          },
          visible: true,
          value: 1
        });

        if (!this.office365AlertShowed) {
          this.office365AlertShowed = true;
          this.configOffice365();
        }
      }
    }
    //},
    //error: (error: any) => { }
    //});
  }

  configOffice365() {
    this.coreDialogService.confirm({
      message: "El administrador del sistema ha habilitado la integración con Office 365 para tu usuario.<br>Para poder utilizarla, debes autorizar la conexión de AxialCRM con tu cuenta.<br><br>¿Quieres autorizar la conexión ahora?",
      header: "Conexión con Office365",
      icon: "pi pi-info-circle",
      accept: () => {
        this.office365Service.authorize().subscribe({
          next: (data: any) => {
            if (data.success) {
              location.assign(data.url);
            } else {
              this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: 'No hemos podido generar tu autorización' });

            }
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
          }
        })
      },
      reject: () => {

      }
    });
  }

  async loadGMailConfigNotification() {
    //comprueba si tiene pendiente conectar su cuenta con gmail
    let data: any = await firstValueFrom(this.gmailService.me());
    //this.gmailService.me().subscribe({
    //  next: (data: any) => {
    if (data != null) {
      let requireConnection: boolean = false;
      if (data.mail.enabled) requireConnection = true;
      if (data.mail.allow_send) requireConnection = true;
      if (data.calendar.enabled) requireConnection = true;
      if (data.contacts.enabled) requireConnection = true;
      if (!data.connected && requireConnection) {
        this.totalAlerts += 1;
        this.alertsNotifications.push({
          code: "gmail",
          label: '<span class="p-badge p-badge-danger alertsTopbarBadge">1</span>&nbsp;&nbsp;Google Mail',
          icon: 'pi pi-fw pi-cog',
          command: (event) => {
            //abrir configurador
            this.configGMail();
          },
          visible: true,
          value: 1
        });

        if (!this.gmailAlertShowed) {
          this.gmailAlertShowed = true;
          this.configGMail();
        }
      }
    }
    //  },
    //  error: (error: any) => { }
    //});
  }

  configGMail() {
    this.coreDialogService.confirm({
      message: "El administrador del sistema ha habilitado la integración con Google Mail para tu usuario.<br>Para poder utilizarla, debes autorizar la conexión de AxialCRM con tu cuenta.<br><br>¿Quieres autorizar la conexión ahora?",
      header: "Conexión con Google Mail",
      icon: "pi pi-info-circle",
      accept: () => {
        this.gmailService.authorize().subscribe({
          next: (data: any) => {
            if (data.success) {
              location.assign(data.url);
            } else {
              this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: 'No hemos podido generar tu autorización' });

            }
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
          }
        })
      },
      reject: () => {

      }
    });
  }
}
