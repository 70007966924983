import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactsListComponent } from './contacts-list/contacts-list.component';
import { OrganizationsContactsDetailComponent } from '../organizations/organizations-detail/organizations-contacts-detail/organizations-contacts-detail.component';

const routes: Routes = [
  { path: "contacts/organization/:organization_id", component: ContactsListComponent },
  { path: "contacts/:id", component: OrganizationsContactsDetailComponent },
  { path: "contacts", component: ContactsListComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContactsRoutingModule { }
