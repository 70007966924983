<ul class="breadcrumb">
  <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}}
      </span>{{'general.breadcrumb.home' | translate}}</a></li>
  <li [hidden]="contact.organization_id == null"><i class="pi pi-angle-right"></i></li>
  <li [hidden]="contact.organization_id == null"><a
      [routerLink]="['/organizations']">{{componentService.getEntityName({code: 'organization', name: 'Organización'},
      true)}}</a></li>
  <li [hidden]="contact.organization_id == null"><i class="pi pi-angle-right"></i></li>
  <li [hidden]="contact.organization_id == null">
    <a [routerLink]="['/organizations', 0, contact.organization_id]">{{contact.organization?.name}}</a>
  </li>
  <li><i class="pi pi-angle-right"></i></li>
  <li *ngIf="contact.organization_id != null"><a (click)="navigateToListFilteredByOrganization()">{{view.getEntityBaseName(true)}}</a></li>
  <li *ngIf="contact.organization_id == null"><a [routerLink]="['/contacts']">{{view.getEntityBaseName(true)}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li>
    <a [routerLink]="['/contacts', contact.id]">{{contact.name}}</a>
  </li>
</ul>
<app-view #view [code]="'contacts.edit'" [model]="contact" (onEvent)="onEvent($event)"></app-view>