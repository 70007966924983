import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewsService } from 'src/app/core/view/views.service';
import { MailingDetailComponent } from 'src/app/mailing/mailing-detail/mailing-detail.component';
import { ViewComponent } from '../../../core/view/view.component';
import { OrganizationsService } from '../../organizations.service';
import { OrganizationsDetailGeneralComponent } from '../organizations-detail-general/organizations-detail-general.component';
import { ContactsService } from './contacts.service';
import { MailMarketingNotificationToStatsComponent } from 'src/app/mail-marketing/mail-marketing-notification-to-stats/mail-marketing-notification-to-stats.component';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ComponentService } from '../../../core/view/component.service';
import { CampaignsContactsService } from 'src/app/campaigns/campaigns-contacts.service';
import { AddCampaignsToEntityComponent } from 'src/app/campaigns/add-campaigns-to-entity/add-campaigns-to-entity.component';

@Component({
  selector: 'app-organizations-contacts-detail',
  templateUrl: './organizations-contacts-detail.component.html',
  styleUrls: ['./organizations-contacts-detail.component.scss']
})
export class OrganizationsContactsDetailComponent implements OnInit {
  public contact: any = {};
  public organization: any = {};
  public last_organization_id;
  public previousUrl;
  @ViewChild("view") public view: ViewComponent;
  constructor(
    private route: ActivatedRoute,
    private organizationsService: OrganizationsService,
    private campaignContactsService: CampaignsContactsService,
    private messageService: MessageService,
    private contactService: ContactsService,
    //private config: DynamicDialogConfig,
    //private dynamicDialogRef: DynamicDialogRef,
    private coreDialogService: CoreDialogService,
    private dialogService: DialogService,
    private router: Router,
    private viewsService: ViewsService,
    private _location: Location,
    private translateService: TranslateService,
    public componentService: ComponentService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.loadData(params["id"]);
    });
    this.route.queryParams.subscribe(params => {
      this.previousUrl = params['returnUrl'];
    });
  }
  loadData(id: number) {
    if (id != 0) {
      this.contactService.get(id).subscribe({
        next: (data: any) => {
          this.contact = data;
          this.last_organization_id = this.contact.organization_id;
          //this.view.setModel(this.contact);
          this.refreshAll();
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    } else {
      this.route.params.subscribe(params => {
        this.contact.organization_id = parseInt(params["organizationId"]);
      });
      //this.contact.organization_id = this.config.data.id;
    }
  }
  loadDataOrganization(id: number) {
    this.organizationsService.get(id).subscribe({
      next: (data: any) => {
        this.organization = data;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    })
  }
  cancel() {
    this._location.back();
    //this.dynamicDialogRef.close();
  }
  save(event: any) {
    if (!event.valid) {
      this.messageService.add({
        closable: false, severity: "error", detail: this.translateService.instant("component.contacts.detail.form_incomplete")
      });
      return;
    }
    if (this.contact.email != null) this.contact.email = this.contact.email.trim();
    if (this.contact.email != null && this.contact.email.length > 0 && !this.validateEmail(this.contact.email)) {
      this.messageService.add({
        closable: false, severity: "error", detail: this.translateService.instant("component.contacts.detail.invalid_mail")
      });
      return;
    }

    if (typeof this.contact.id === "undefined") {
      this.contactService.add(this.contact).subscribe({
        next: (data: any) => {
          this.messageService.add({
            closable: false, severity: 'success', summary: this.translateService.instant("component.contacts.detail.add_contact_correct")
          });
          //this.dynamicDialogRef.close(data);
          if (this.previousUrl != null && this.previousUrl != "") {
            this.router.navigate([this.previousUrl]);
          } else {
            this._location.back();
          }
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    } else {
      if (this.last_organization_id != this.contact.organization_id) {
        this.confirmMoveData();
      } else {
        this.contactService.save(this.contact.id, this.contact).subscribe({
          next: (data: any) => {
            if (this.contact.disabled == true) {
              this.alertDisable();
            } else {
              this.messageService.add({
                closable: false, severity: "success", detail: this.translateService.instant("component.contacts.detail.save_contact_correct")
              });
              //this.dynamicDialogRef.close(data);
              if (this.previousUrl != null && this.previousUrl != "") {
                this.router.navigate([this.previousUrl]);
              } else {
                this._location.back();
              }
            }
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
          }
        });
      }
    }
  }

  confirmMoveData() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.contacts.detail.confirm_move_data"),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.contactService.changeOrganizationAndSave(this.contact.id, this.contact, true).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.contacts.detail.save_contact_correct") });
            //this.dynamicDialogRef.close(data);
            this._location.back();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
          }
        });
      },
      reject: () => {
        this.contactService.changeOrganizationAndSave(this.contact.id, this.contact).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.contacts.detail.add_contact_correct") });
            this._location.back();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
          }
        });
      }
    })
  }

  delete() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.organizations.delete_message", { name: this.contact.name }),
      header: this.translateService.instant("general.confirmation_delete_title"), // this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.contactService.delete(this.contact.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            //this.dynamicDialogRef.close();
            //this._location.back();
            if (this.previousUrl != null && this.previousUrl != "") {
              this.router.navigate([this.previousUrl]);
            } else {
              this.router.navigate(['/contacts']);
            }
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    })
  }
  newMail(emailAcc) {
    const ref = this.dialogService.open(MailingDetailComponent, {
      data: {
        to: emailAcc,
        entity_id: 2,
        entity_pk_id: this.contact.id,
        organization_id: this.contact.organization_id,
        contact_id: this.contact.id,
        //template_type_id: 1,
        template_type_code: "MAIL",
        searchRelated: true
      },
      header: this.translateService.instant("general.new_mail_title"),//"Nuevo mensaje de correo",
      width: '70%',
    });
    ref.onClose.subscribe((data: any) => {
      if (data) {
        //this.dynamicDialogRef.close(data);
        location.reload();
      }
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
    });
  }

  isDuplied(contact, event) {
    if (!event.valid) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    this.contactService.isDuplied(contact).subscribe(
      data => {
        if (data == true) {
          this.coreDialogService.notify({
            message: this.translateService.instant("component.contacts.detail.contact_duplicate"), //"Ya existe un contacto con estos datos proporcionados.",
            header: this.translateService.instant("general.error"),
            icon: 'far, exclamation-circle',
            color: 'var(--orange-500)',
            accept: () => { }
          });
        } else {
          this.save(event);
        }
      }
    );
  }

  refreshAll() {
    this.view.view?.configuration?.components?.array?.forEach(component => {
      this.view.fireEvent(component.code, "reload");
    });
    this.view.fireEvent("contacts.campaigns", "reload");
  }


  openNotificationStats() {
    const ref = this.dialogService.open(MailMarketingNotificationToStatsComponent, {
      data: {
        //organization_id: this.contact.organization_id,
        contact_id: this.contact.id,
      },
      header: this.translateService.instant("component.contacts.detail.report_clicks"),
      width: '70%'
    });
    ref.onClose.subscribe((data: any) => {
      //nothing
    })
  }

  openCall(tlfn: string): void {
    // Verificar si el navegador soporta la función de llamada telefónica
    if ('open' in window) {
      // Utilizar el protocolo tel: seguido del número de teléfono
      window.open(`tel:${tlfn}`);
    } else {
      // Manejar el caso donde la función de llamada telefónica no está soportada
      console.log('Tu navegador no soporta la función de llamada telefónica.');
    }
  }


  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  openEmails() {
    if (this.contact.id != null) this.router.navigate(["/emails", (this.organization.id ? this.organization.id : 0), this.contact.id]);
  }

  alertDisable() {
    this.coreDialogService.notify({
      message: this.translateService.instant("component.contacts.detail.disabled_contact_alert"),//"Se ha dado de baja el contacto.",
      header: this.translateService.instant("general.error"),
      icon: 'far exclamation-circle',
      color: 'var(--orange-500)',
      accept: () => {
        this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.contacts.detail.save_contact_correct") });
        this._location.back();
      }
    });
  }
  unlinkCampaign(campaign) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.contacts.unlink_campaign", { name: campaign.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.campaignContactsService.unlinkContact({ contact_id: this.contact.id, campaign_id: campaign.id }).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.refreshAll();
            //this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        })
      },
      reject: () => {

      }
    });
  }
  openCampaign(campaign) {
    window.open("/campaigns/" + (campaign.id ? campaign.id : 0));
  }

  addCampaigns() {
    const ref = this.dialogService.open(AddCampaignsToEntityComponent, {
      data: {
        id: this.contact.id,
        entity: 'contact'
      },
      header: this.translateService.instant("component.campaigns.addCampaigns"),
      width: '70%'
    });
    ref.onClose.subscribe((data: any) => {
      this.refreshAll();
    });
  };
  navigateToListFilteredByOrganization() {
    this.router.navigate(['/contacts/',"organization", this.contact.organization_id]);
  }
  onEvent(event: any) {
    if (event.event == "refreshAll" && event.action.name == "refreshAll") this.loadData(this.contact.id);
    if (event.component == "contacts.detail") {
      if (event.event == "action" && event.action.name == "openNotificationStats") this.openNotificationStats();
      if (event.event == "action" && event.action.name == "delete") this.delete();
      if (event.event == "action" && event.action.name == "cancel") this.cancel();
      if (event.event == "action" && event.action.name == "save") this.isDuplied(this.contact, event);
      if (event.event == "fieldAction" && event.action.name == "new-mail") this.newMail(event.value);
      if (event.event == "action" && event.action.name == "openNotificationsStats") this.openNotificationStats();
      if (event.event == "action" && event.action.name == "openEmails") this.openEmails();
      if (event.event == "fieldAction" && event.action.name == "openCall") this.openCall(event.value);
    }
    else if (event.component == "contacts.campaigns") {
      if (event.event == "action" && event.action.name == "add") this.addCampaigns();
      if (event.event == "action" && event.action.name == "edit") this.openCampaign(event.data);
      if (event.event == "action" && event.action.name == "unlink") this.unlinkCampaign(event.data);
    }
    if (event.component == "view" && event.event == "entity-loaded") {
      //this.config.header = typeof (this.contact.id) !== "undefined" ? "Detalles del " + this.view.getEntityBaseName() : "Nuevo " + this.view.getEntityBaseName();
    }
  }
}
